<template>
  <div>
    <div class="dialog-content dark margin-top-xl flex">
      <div class="left padding margin-left">
        <div class="text-36 margin-top text-bold title text-black">获取专属企业AI员工</div>
        <div class="subTitle margin-bottom margin-top-xs text-sm">由全球顶尖RPA专家提供系统培训与技术支持，帮助企业和个人快速起步</div>
        <div class="padding left-content">
          <div>
            <i class="el-icon-success"></i>
            <span class="text-df padding-left-xs">01 专属大模型</span>
          </div>
          <div class="flex-center content-item margin-bottom margin-left-sm">
            <div class="text-title flex">专属AI大模型员工帮训</div>
            <div class="text-title flex">知识库语料库完善升级</div>
          </div>
          <div>
            <i class="el-icon-success"></i>
            <span class="text-df padding-left-xs">02 专业陪跑</span>
          </div>
          <div class="flex-center content-item margin-bottom margin-left-sm">
            <div class="text-title flex">高级客成专家实战陪跑</div>
            <div class="text-title flex">后台系统落地包教包会</div>
          </div>
          <div>
            <i class="el-icon-success"></i>
            <span class="text-df padding-left-xs padding-top-xs">03 行业案例</span>
          </div>
          <div class="flex-center content-item margin-left-sm">
            <div class="text-title flex">100+企业真实运营案例</div>
            <div class="text-title flex">AIGC行业情报实时更新</div>
          </div>
        </div>
        <div v-if="isMobile" class="text-center margin-top">
          <el-button type="primary" @click="onSubmit"> <i class="icon-gift"></i> 立即获取</el-button>
        </div>
      </div>
      <div class="right margin-right" v-if="!isMobile">
        <el-form ref="form" class="margin-top flex-center justify-between flex-wrap" :rules="rules" :model="form">
          <el-form-item label="姓名" prop="name" style="width: 47%;">
            <el-input placeholder="请输入姓名" maxlength="50" v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="公司名" prop="companyName" style="width: 47%;">
            <el-input placeholder="请输入公司名" maxlength="50" v-model="form.companyName"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone" style="width: 47%;">
            <el-input placeholder="请输入手机号" maxlength="11" v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="私域规模" prop="scale" style="width: 47%;">
            <el-select v-model="form.scale" style="width: 100%;" popper-class="select-popper-class"
              placeholder="请将您的私域规模告知我们">
              <el-option label="5k以下" value="5k以下"></el-option>
              <el-option label="5k-3w" value="5k-3w"></el-option>
              <el-option label="3w-10w" value="3w-10w"></el-option>
              <el-option label="10w-50w" value="10w-50w"></el-option>
              <el-option label="50w-100w" value="50w-100w"></el-option>
              <el-option label="100w以上" value="100w以上"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="留言" prop="messageBoard" style="width: 100%">
            <el-input placeholder="请输入留言" maxlength="255" v-model="form.messageBoard"></el-input>
          </el-form-item>
          <div class="text-center margin-top" style="width: 100%">
            <el-button type="primary" @click="onSubmit"> <i class="icon-gift"></i> 立即获取</el-button>
          </div>
        </el-form>

      </div>
    </div>
    <div class="margin-bottom-xxl padding-top-sm"></div> 
    <!-- <div class="dialog-bottom flex-center flex-wrap justify-around">
      <div class="flex-center">
        <i class="icon icon-1"></i>
        省级高新技术企业
      </div>
      <div class="flex-center">
        <i class="icon icon-2"></i>
        云服务信息安全管理体系认证
      </div>
      <div class="flex-center">
        <i class="icon icon-3"></i>
        云服务信息安全管理体系认证
      </div>
      <div class="flex-center">
        <i class="icon icon-4"></i>
        信息安全保护第五级
      </div>
    </div> -->
    <el-dialog :custom-class="`index-dialog ${isMobile ? 'mobile' : ''}`" :append-to-body="true" width='300px'
      top="32vh" :close-on-press-escape="false" :visible.sync="dialogVisible" :show-close="false">
      <div class="dialog-content-success text-center">
        <div><i class="el-icon-success"></i></div>
        <div class="grey flex-center justify-center margin-left-xs margin-top-xs">
          <el-statistic format="s" @finish="hilarity" :value="deadline" time-indices title="提交成功，我们将在24小时内联系您">
            <template slot="suffix">
              秒后自动关闭
            </template>
          </el-statistic>

        </div>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import { websiteSave } from "@/api/index.js"
export default {
  props: { isMobile: { type: Boolean, default: false } },
  data() {
    return {
      form: {
        name: "",
        phone: "",
        companyName: "",
        messageBoard: "",
        scale: ""
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        phone: [{ required: true, message: "请输入手机号码", trigger: "blur" }, {
          pattern: /^(?:(?:\+|00)86)?1(?:3[\d]|4[5-79]|5[0-35-9]|6[5-7]|7[0-8]|8[\d]|9[189])\d{8}$/,
          message: "请输入正确的手机号码",
          trigger: "blur"
        }],
        companyName: [{ required: true, message: "请输入公司名称", trigger: "blur" }, {
          pattern: /.{2,}/,
          message: "公司名称长度必须为2~50字符",
          trigger: "blur"
        }],
        scale: [{ required: true, message: "请选择私域好友规模", trigger: "blur" }]
      },
      deadline: "",
      dialogVisible: false,
    }
  },
  methods: {
    onSubmit() {
      if (this.isMobile) {
        this.$emit("openDialog")
        return;
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          websiteSave(this.form).then(res => {
            if (res.data.code == 0) {
              this.deadline = Date.now() + 1000 * 4;
              this.dialogVisible = true;
            }
          })
        }
      })
    },
    hilarity() {
      this.visible = false;
      this.dialogVisible = false;
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-content {
  border-radius: 20px;
  margin: 100px auto 0;
  width: 1360px;
  height: 500px;

  .left {
    flex: 1;
    // width: 575px;
    // height: 500px;

    // background: linear-gradient(116deg, rgba(212, 225, 255, 0.9882) -3%, #F4F8FF 81%);
    .left-content {
      // width: 538px;
      // height: 270px;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.05);

      .content-item {
        max-width: 340px;
      }
    }
  }

  .right ::v-deep {
    flex: 1;
    // width: 575px;
    padding: 30px;

    .el-form-item__label {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
    }

    .el-input__inner {
      height: 48px;
      background: #45454B;
      border-color: #45454B;
      border-radius: 8px;
      color: #fff;

      &::-webkit-input-placeholder {
        color: #999999;
        font-size: 14px;
      }
    }



    .el-form-item {
      margin-bottom: 15px;
    }

    .icon-gift {
      width: 18px;
      height: 16px;
      margin-right: 5px;
      display: inline-block;
      background: url(https://oss.isuiyou.cn/4009f1b49698608f93ef62ff4c9fe074f5acc6d6528590fc9fae5cbf1267c4df.png) center center no-repeat;
      background-size: contain;
      vertical-align: bottom;
    }
  }

  .el-icon-success {
    font-size: 14px;
    color: #26B260;
  }

  .icon-logo {
    width: 182px;
    height: 46px;
    display: block;
    margin: 20px auto;
    background: url(https://oss.isuiyou.cn/74a944cbb9cb578d33b0a58bc90163a56c969698aa3b29dda1b6367bb65b605e.png) center center no-repeat;
    background-size: contain;
  }

  .icon-bo {
    width: 22px;
    height: 19px;
    display: block;
    background: url(https://oss.isuiyou.cn/51a9f3fdafcfe8bc48d29dff90bb710c99656065a0bf3dbad3f5cd37de7d5639.png) center center no-repeat;
    background-size: contain;
  }

  .real-bo {
    width: 48px;
    height: 41px;
    background: url(https://oss.isuiyou.cn/scrm/dbc1b6f7-d170-46ba-abc5-e5f10b2b40b8/real-bo.png) center center no-repeat;
    background-size: contain;
  }

  .logo {
    width: 131px;
    height: 35px;
    background: url(https://oss.isuiyou.cn/scrm/97741b56-de1e-4b6d-abce-342bec7eb2e9/logo.png) center center no-repeat;
    background-size: contain;
  }



  .text-title {
    // margin-top: 10px;
    width: 420px;
    margin-right: 15px;
    margin-left: 5px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
    // &::before {
    //   content: "";
    //   display: block;
    //   width: 13px;
    //   height: 13px;
    //   margin-top: 4px;
    //   margin-right: 20px;
    //   background: url(https://oss.isuiyou.cn/d4fc57ae001c33bf286f7d8836d5ddb042d8e2b9e32855411fe95dfb4409cc3a.png) center center no-repeat;
    //   background-size: contain;
    // }
  }
}

.dark {
  color: #fff;
  background-color: #1D1D1F;

  .title {
    color: #fff;
  }

  .subTitle {
    color: rgba(255, 255, 255, 0.5);
  }
}

.el-button--primary {
  width: 280px;
  height: 60px;
  margin: 0 auto;
  font-size: 16px;
  color: #000;
  font-weight: bold;
  border-radius: 681px;
  opacity: 1;
  color: #333;
  background: #FFFFFF;
  border: 0 none;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // background: linear-gradient(285deg, #5DA2FE 13%, #3981F8 89%);
}

.dialog-bottom {
  width: 1360px;
  margin: 0 auto;
  font-size: 16px;
  padding-top: 16px;
  color: #999999;
  padding-bottom: 80px;

  .icon {
    width: 60px;
    height: 60px;
    margin-right: 5px;
  }

  .icon-1 {
    background: url(https://oss.isuiyou.cn/46c370ffaf0d2ccf8638c9c9392a03c8818ec134275100b29dd1c4236c7494d8.png) center center no-repeat;
    background-size: contain;
  }

  .icon-2 {
    background: url(https://oss.isuiyou.cn/5d303aef23d0f68391511949eeb9475eed19cddbea47ea280859f3a2043b9d2c.png) center center no-repeat;
    background-size: contain;
  }

  .icon-3 {
    background: url(https://oss.isuiyou.cn/5cc76e42ef63a4468af0e52bb76221502ecd312ceb8df2e6637b6f6397f67883.png) center center no-repeat;
    background-size: contain;
  }

  .icon-4 {
    background: url(https://oss.isuiyou.cn/eb37a357e53b873ba7603848a7b4a09496cc481a1f250714c4c7dc67e9ecf906.png) center center no-repeat;
    background-size: contain;
  }
}

.dialog-content-success ::v-deep {
  height: 180px;
  padding: 28px 30px;

  .head {
    font-size: 14px;
    font-weight: bold;
  }

  .el-statistic .con,
  .el-statistic .con .number {
    font-size: 13px;
    color: #6F7C95;
    padding: 0;
  }

  .el-statistic .con {
    justify-content: center;
    margin-top: 10px;
  }

}
</style>
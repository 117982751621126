<template>
    <div id="case" class="content padding-top-sm">
        <div v-animate="{ appearClass: 'animate__fadeInDown' }"
            class="text-58 text-center margin-tb-xl text-bold animate__animated">
            开箱即用，与品牌共筑自动化场景</div>
        <div v-animate="{ appearClass: 'animate__fadeInRight' }" class="tabs margin-tb-xl animate__animated">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane v-for="(item, index) in tabsList" :key="index" :name="item.name">
                    <div class="label flex-center justify-center" slot="label">
                        <el-image class="title-icon"
                            :src="activeName == item.name ? item.activeIcon : item.icon"></el-image>
                        {{ item.label }}
                    </div>
                </el-tab-pane>

            </el-tabs>
        </div>
        <div v-animate="{ appearClass: 'animate__fadeInLeft' }" class="carousel padding-bottom-xl animate__animated">
            <v-touch tag='p' @swipeleft="swiperleft" class="v-touch" @swiperight="swiperright">
                <p style="margin: 0;">
                    <el-carousel ref="carousel" :interval="5000" :initial-index="initialIndex" @change="carouselChange"
                        :autoplay="false">
                        <el-carousel-item :name="item.name" v-for="(item, index) in tabsList" :key="item.name">
                            <div class="carousel-list-item text-white" :class="[item.class]">
                                <div class="label">{{ item.label }}</div>
                                <div class="title margin-top-xs">{{ item.title }}</div>
                                <div class="text margin-tb">{{ item.text }}</div>
                                <div style="width: 250px;" :style="{ paddingTop: index == 5 ? 0 : 'auto' }"
                                    class="padding-tb flex-center justify-between">
                                    <div class="text-center margin-right">
                                        <div class="text-df"><span class="text-36">{{ item.num1 }}</span>%</div>
                                        <div class="text-8a">{{ item.text1 }}</div>
                                    </div>
                                    <div class="text-center">
                                        <div class="text-df"> <span class="text-36">{{ item.num2 }}</span>%</div>
                                        <div class="text-8a">{{ item.text2 }}</div>
                                    </div>
                                </div>
                                <div class="carousel-btn" :class="[index === 0 ? 'margin-top' : '']">
                                    <el-button @click="openDialog" plain><i class="icon-gift"></i>
                                        获取同款自动化解决方案</el-button>
                                </div>
                            </div>
                            <!-- <el-image :src="item.url"></el-image> -->
                        </el-carousel-item>
                    </el-carousel>
                </p>
            </v-touch>

        </div>

    </div>
</template>

<script>
export default {
    props: { isMobile: { type: Boolean, default: false } },
    data() {
        return {
            activeName: "nuangKang",
            initialIndex: 0,
            tabsList: [{
                name: "nuangKang",
                label: "电商",
                title: "某国货之光彩妆品牌",
                text: "用户增长红利见顶，获客成本增高，驱使品牌发展私域流量，深耕存量用户价值。Real-bot助力品牌与顾客建立强连接，全场景，全时段，全方位的满足消费者需求，深度运营，持续挖掘消费者价值。",
                num1: "200",
                text1: "GMV增长",
                num2: "60",
                text2: "复购率提升",
                icon: "https://oss.isuiyou.cn/98c937c16632f511fbd642c163ab2de30385ed83735b69a9928d43f0763e3d0f.png",
                activeIcon: "https://oss.isuiyou.cn/c43f21b11800908626e8d8438e157005f7966da514e8fd2dec2960da9382a47b.png",
                url: "https://oss.isuiyou.cn/scrm/c4d74e97-a542-4e9a-adab-d52ad9dd675e/组 26298@2x.png",
                class: "bg bg-1",
                // mobileImage: "https://oss.isuiyou.cn/9878c4bffa65ca49230cf6645fe39ab34bd4bbcea3d8343ce5995ef694a9fe03.png",
                // textTile: "https://oss.isuiyou.cn/6092dc13fc6c9b8b15e6f22d301b17972303a5499b3784d5162e3157a40815ed.png"

            }, {
                name: "groupBuy",
                label: "教培",
                title: "某头部在线教育品牌",
                text: "作为一家靠口碑做起来的品牌，Real-bot助力品牌提升用户的学习有效性、互动性，以及相应的配套服务；学习有效性主要体现在日常的督促、课程打卡，互动性体现在和品牌的交流、联络，配套服务即整个用户生命旅程期间的售前、售后服务和相对更有温度的人文关怀。",
                num1: "300",
                text1: "日均接待量提升",
                num2: "13",
                text2: "沉默用户唤醒率提升",
                icon: "https://oss.isuiyou.cn/bc25203622fd65141ab636b38a95f023beedd92ae3ef48b7fe8602f4dd1e974b.png",
                activeIcon: "https://oss.isuiyou.cn/da3a32b79771dcf8781eadd9beb0844004d40f2d82bbf484bf600db7f5853889.png",
                url: "https://oss.isuiyou.cn/scrm/320081c7-1323-40be-8721-5368aaafcc69/组 26299@2x.png",
                class: "bg bg-2",
                // mobileImage: "https://oss.isuiyou.cn/1ca79710d189071d70a0d553eb33d55f2d373b767fba7cf4bb14d6c31a8f739a.png",
                // textTile: "https://oss.isuiyou.cn/846942b58d84dbf5062ce6fdb90e63fcfc941f2057e4ca0785d6ce93c4c14635.png"
            },
            {
                name: "TCM",
                label: "大健康",
                title: "某互联网医疗TOP3品牌",
                text: "健康消费需求风口之下，新客接待络绎不绝，用户问题层出不穷，需要精准挖掘用户健康管理需求，构建闭环全周期健康管理服务解决方案，使用Real-bot解决新客接待、用户画像完善、用户需求报告输出、以及用户全周期的关怀，打造服务差异化。",
                num1: "2000",
                text1: "问诊效率提升",
                num2: "200",
                text2: "售后服务满意度",
                icon: "https://oss.isuiyou.cn/a92f2abef497585b70c7331a09afaae76427329990cc28177b11ff37e3abf085.png",
                activeIcon: "https://oss.isuiyou.cn/66a98c99aa0678e2360ba81267e39a0dc85c33de4cee5f8af3c5a8bab1fe2130.png",
                url: "https://oss.isuiyou.cn/scrm/f875813c-8448-41b0-a75f-47cfe9d12cbf/组 26300@2x.png",
                class: "bg bg-3",
                // mobileImage: "https://oss.isuiyou.cn/8fd813a5789c72f9b3e9a1edaadb86781e619e70683467742b6c9ecd9f5e7cd8.png",
                // textTile: "https://oss.isuiyou.cn/07b810170939686a379350ae1bbe5d44578932fb1394e6dd7013d1f3eb801be1.png"
            },
            {
                name: "dress",
                label: "服饰",
                title: "某白领女性穿搭品牌",
                text: "服装货源丰富，用户的主要购买模式为经导购推荐后产生消费，公司一半以上的员工都需要接待线上大量用户的穿搭咨询，训练一个懂穿搭的员工很难，员工需要记住每位用户的穿搭喜好更难，Real-bot完美解决咨询量过载问题，后台用户身材信息、画像信息一应俱全。",
                num1: "12.7",
                text1: "私域转化率提升至",
                num2: "100",
                text2: "新品触达率",
                icon: "https://oss.isuiyou.cn/bc2d5212af4ef5ce7ebba19894ecf63b295f638e4391635d7a547b7d53261a8a.png",
                activeIcon: "https://oss.isuiyou.cn/de79e8a3e40304ac9eb03c6e6d2dfc32d4d0c100f884442ede5cb622df356efd.png",
                url: "https://oss.isuiyou.cn/scrm/a31f015a-4d1b-41dd-b534-0c93bfb30c30/组 26303@2x.png",
                class: "bg bg-4",
            },
            {
                name: "tenement",
                label: "母婴",
                title: "某国际全品类头部品牌",
                text: "品牌用户群体庞大，受众面广，分完地区再分人群，全国近2000家门店，10000+以上的社群，管控困难，各种营销活动配合执行度极低，无法达到预期效果。Real-bot助力品牌社群分类分群分标签管理，营销任务直接下发执行力100%，用户问题快速响应，新品上市从推出到落地仅需一步轻松传达用户，绝无信息差。",
                num1: "50",
                text1: "用户活跃度提升",
                num2: "30",
                text2: "增量ROI提升",
                icon: "https://oss.isuiyou.cn/adb725f7236ea3933f6f19d2e36537fe4f35aecd908c98ba46b02f85ab0612dc.png",
                activeIcon: "https://oss.isuiyou.cn/ab5be7ae241f6a43f4e0d7a7152aa7d253ea0478e651fbc9b1e61010aae01629.png",
                url: "https://oss.isuiyou.cn/scrm/d787b19b-bb52-4db2-8412-8427ed8e2758/组 26302@1x.png",
                class: "bg bg-5",
                // mobileImage: "https://oss.isuiyou.cn/a0a22894d510cb9581c82083e8f9c022536465818fe72c2b4addd9d3835b5de6.png",
                // textTile: "https://oss.isuiyou.cn/1c267cba954da3ac62d6ec5ce62d29f0214a18db19e3c9ad9991049ef0950535.png"
            },
            {
                name: "lawOffice",
                label: "食品",
                title: "某知名乳制品品牌",
                text: "低客单高消费频次的产品特性，使得品牌非常关注客户的复购率和连带消费率，但消费者对于品牌的粘性不强，不同地区用户对于乳制品的偏好也不尽相同，尽管私域用户体量庞大，在对用户画像一无所知的情况下，很难彻底将私域“玩”起来，Real-bot助力品牌完善用户画像，有策略的给用户推荐合适的乳制品，且更加注重长期订奶用户的服务，增加用户关怀，提升用户品牌忠诚度。",
                num1: "50",
                text1: "复购率提升",
                num2: "49",
                text2: "连带率提升至",
                icon: "https://oss.isuiyou.cn/16053b176050e2e45295f65063eecd1ae3a65fd84c3db5022173f481b647a0eb.png",
                activeIcon: "https://oss.isuiyou.cn/a9361f95e7fc607548b0fb39b981b9c2f2f94ac4542855a62e61c240fe804b30.png",
                url: "https://oss.isuiyou.cn/scrm/467bd9aa-ed52-4194-a117-a5c37941ebfb/组 26301@1x.png",
                class: "bg bg-6",
                // mobileImage: "https://oss.isuiyou.cn/8194e3f3733ef13c73dffd789b77d66d2f9cbd8d92177e81941ee44c1d9d255a.png",
                // textTile: "https://oss.isuiyou.cn/a7d14f106950e639983dd60b1c88d5399d110b83b6c8634225f3abc272e78a42.png"
            }]
        }
    },
    methods: {
        carouselChange(e) {
            this.activeName = this.tabsList[e].name;
        },
        handleClick(e) {
            this.$refs.carousel.setActiveItem(e._props.name)
        },
        createAnimate(currentPosition) {
            // this.showMainBottomContainer = currentPosition > this.$refs.mainBottomContainer.offsetTop
        },
        swiperleft() {
            this.initialIndex = this.initialIndex == 4 ? 0 : ++this.initialIndex;
            this.activeName = this.tabsList[this.initialIndex].name;
            this.$refs.carousel.setActiveItem(this.activeName);
        },
        swiperright() {
            this.initialIndex = this.initialIndex == 0 ? 4 : --this.initialIndex;
            this.activeName = this.tabsList[this.initialIndex].name;
            this.$refs.carousel.setActiveItem(this.activeName);

        },
        openDialog() {
            this.$emit("openDialog")
        }
    },
}
</script>

<style lang="scss" scoped>
.content {
    width: 98%;
    margin: 0 auto;
    background: #EFF5FF;
    border-radius: 50px;
}

.tabs ::v-deep {
    width: 1280px;
    margin: 80px auto;


    .el-tabs__header {
        width: 1000px;
        margin: 0 auto;
        // background: #ECF1F9;
        border-radius: 30px;
    }

    .el-tabs__nav-wrap::after {
        display: none;
    }

    .el-tabs__active-bar {
        background-color: #000000;
    }

    .el-tabs__item {
        height: 56px;
        line-height: 56px;
        text-align: center;
        font-size: 22px;
        padding: 0;
        padding-right: 20px;
        color: #6F7C95;
        transition: color 0.3s ease-in-out;

        .label {
            width: 150px;

            &:hover {
                background: rgba(216, 216, 216, 0.3);
                border-radius: 30px;
            }

            .title-icon {
                width: 24px;
                height: 24px;
                margin-right: 8px;
            }
        }

        &.is-active {
            color: #fff;
        }
    }

    .el-tabs__active-bar {
        width: 150px !important;
        height: 56px;
        border-radius: 30px;
        z-index: -1;
    }
}

.carousel ::v-deep {
    max-width: 1480px;
    margin: 40px auto 20px;

    // max-width: 1280px;
    .el-carousel__indicators {
        display: none;
    }

    .el-icon-arrow-left,
    .el-icon-arrow-right {
        color: #646D7C;
        font-weight: bold;
    }

    .el-image {
        img {
            vertical-align: top;
        }
    }

    .el-carousel__arrow {
        display: none;
    }

    .el-carousel__arrow--left {
        top: 25%;
        left: -55px;
    }

    .el-carousel__arrow--right {
        top: 25%;
        right: -55px;
    }

    .el-carousel__container {
        height: 560px;
        max-width: 1340px;
        margin: 0 auto;
    }
}

.carousel-list-item {
    background-size: contain;
    height: 560px;

    .label {
        width: 73px;
        height: 39px;
        line-height: 39px;
        border-radius: 4px;
        background: #FD3334;
        font-size: 18px;
        text-align: center;
    }

    .title {
        font-size: 40px;
        font-weight: bold;
        line-height: 72px;
        letter-spacing: 0em;
    }

    .text {
        font-size: 18px;
        width: 885px;
        line-height: 28px;
        letter-spacing: 0em;
    }
}

.bg {
    padding: 72px;
    border-radius: 20px;
}

.bg-1 {
    background: url('https://oss.isuiyou.cn/scrm/c4d74e97-a542-4e9a-adab-d52ad9dd675e/组 26298@2x.png') center center no-repeat;
    background-size: cover;
}

.bg-2 {
    background: url("https://oss.isuiyou.cn/scrm/320081c7-1323-40be-8721-5368aaafcc69/组 26299@2x.png") center center no-repeat;
    background-size: cover;
}

.bg-3 {
    background: url('https://oss.isuiyou.cn/scrm/f875813c-8448-41b0-a75f-47cfe9d12cbf/组 26300@2x.png') center center no-repeat;
    background-size: cover;
}

.bg-4 {
    background: url("https://oss.isuiyou.cn/scrm/a31f015a-4d1b-41dd-b534-0c93bfb30c30/组 26303@2x.png") center center no-repeat;
    background-size: cover;
}

.bg-5 {
    background: url('https://oss.isuiyou.cn/scrm/d787b19b-bb52-4db2-8412-8427ed8e2758/组 26302@1x.png') center center no-repeat;
    background-size: cover;
}

.bg-6 {
    background: url("https://oss.isuiyou.cn/scrm/467bd9aa-ed52-4194-a117-a5c37941ebfb/组 26301@1x.png") center center no-repeat;
    background-size: cover;
}

.carousel-btn {
    .el-button {
        width: 280px;
        height: 60px;
        border-radius: 60px;
        font-size: 16px;
        color: #333 !important;
        border-color: #fff !important;
    }
}

.case-bottom {
    .left {
        padding: 60px 80px;
        width: 624px;
        height: 431px;
        border-radius: 10px;
        background: #F1F4FF;
    }

    .right {
        padding: 60px 82px;
        width: 625px;
        height: 431px;
        border-radius: 10px;
        background: linear-gradient(180deg, #4B94FE 0%, #3971FA 100%);

        .icon-plan {
            width: 19px;
            height: 16px;
            display: inline-block;
            // margin-left: 15px;
            margin-right: 10px;
            background: url(https://oss.isuiyou.cn/720446c1f69d7d315d92af24c6ac0a45a4884fb69262f238127fc30a96b2e26b.png) center center no-repeat;
            background-size: contain;
        }

        .text-white {
            display: flex;
            align-items: baseline;
        }

        .text-title {
            width: 500px;
            height: 38px;
            background-size: contain !important;
            margin-bottom: 20px;
        }
    }
}
</style>
<template>
  <div class="home" :class="[isMobile ? 'mobile' : '']">
    <div class="logo-image">
      <img class="image" :src="require('@/assets/image.png')" alt="logo" />
    </div>
    <navbar ref="navbar" @scrollTo="scrollTo" @openDialog="openDialog"></navbar>
    <AppMain :isMobile="isMobile" @openDialog="openDialog" ref="appMain"></AppMain>
    <!-- <appContent :isMobile="isMobile" ref="appContent" @openDialog="openDialog"></appContent> -->
    <div class="app-content" ref="container">
      <CanDo ref="canDo" style="margin: auto" />
      <Model ref="model" :isMobile="isMobile" style="margin: auto" />
      <!-- <Case :isMobile="isMobile" ref="case"></Case> -->
      <div class="case-bg">
        <Case @openDialog="openDialog" :isMobile="isMobile" ref="case"></Case>
      </div>
      <div class="app-content__bg" ref="bg"></div>
    </div>
    <!-- <div class="case-bg">
      <Case :isMobile="isMobile" ref="case"></Case>
    </div> -->
    <Echoes style="margin: 0 auto" />
    <foot @openDialog="openDialog" :isMobile="isMobile" ref="foot"></foot>
    <app-dialog :isMobile="isMobile" ref="dialog"></app-dialog>
    <div class="bottom-button" v-if="scrollTop > 220 && isMobile">
      <el-button @click="openDialog" class="btn text-lg text-blue solid radius-8 line-blue margin-left-sm"
        plain>定制属于自己的AI员工</el-button>
    </div>
    <rightPopover></rightPopover>
  </div>
</template>

<script>
import AppMain from "@/components/appMain.vue";
import Navbar from "@/components/navbar.vue";
import appContent from "@/components/appContent.vue";
import Foot from "@/components/foot.vue";
import AppDialog from "@/components/appDialog.vue";
import dialogContent from "@/components/dialogContent.vue";
import Case from "@/components/case.vue";
import CanDo from "@/components/can-do";
import Model from "@/components/model/index";
import Echoes from "@/components/echoes";
import rightPopover from "@/components/rightPopover.vue";
import _ from "lodash";
import { scrollTo } from "@/assets/js/scroll-to";
// import renderShadergradient from "@/utils/react-shadergradient";

export default {
  name: "HomeView",
  components: {
    Navbar,
    AppMain,
    Foot,
    appContent,
    AppDialog,
    Case,
    CanDo,
    Model,
    Echoes,
    rightPopover,
  },
  data() {
    return {
      currentPosition: 0,
      isMobile: false,
      scrollTop: 0,
      showBg: false,
      renderShadergradient: null,
    };
  },
  methods: {
    openDialog() {
      this.$refs.dialog.open();
    },
    pageScroll() {
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
    },
    scrollTo(ref) {
      if (this.$refs[ref]) {
        const top = ref == 'canDo' ? this.getOffset(this.$refs[ref].$el).top - 100 : this.$refs[ref].$el.offsetTop;
        scrollTo(top - 100);
      }
    },
    getScroll(target, top) {
      const prop = top ? 'pageYOffset' : 'pageXOffset'
      const method = top ? 'scrollTop' : 'scrollLeft'
      let ret = target[prop]
      if (typeof ret !== 'number') {
        ret = window.document.documentElement[method]
      }
      return ret
    },

    getOffset(element) {
      const rect = element.getBoundingClientRect()

      const scrollTop = this.getScroll(window, true)
      const scrollLeft = this.getScroll(window)

      const docEl = window.document.body
      const clientTop = docEl.clientTop || 0
      const clientLeft = docEl.clientLeft || 0

      return {
        top: rect.top + scrollTop - clientTop,
        left: rect.left + scrollLeft - clientLeft,
      }
    },
    loadBg() {
      // return
      window.requestIdleCallback(() => {
        import("@/utils/react-shadergradient")
          .then((module) => {
            const renderShadergradient = module?.default;
            const { bg } = this.$refs;
            this.renderShadergradient?.unmount();
            this.renderShadergradient = renderShadergradient?.(bg, {
              options: {
                animate: "on",
                // axesHelper: "off",
                bgColor1: "#000000",
                bgColor2: "#000000",
                brightness: 1.4,
                // cAzimuthAngle: 170,
                // cDistance: 3.6,
                // cPolarAngle: 70,
                // cameraZoom: 1,
                color1: "#0019ff",
                color2: "#0029ce",
                color3: "#ffffff",
                // embedMode: "off",
                // envPreset: "city",
                // fov: 50,
                // gizmoHelper: "hide",
                grain: "on",
                // lightType: "env",
                // pixelDensity: 1,
                // positionX: 0,
                // positionY: 0.9,
                // positionZ: -0.3,
                range: "disabled",
                // rangeEnd: 36.4,
                // rangeStart: 0,
                reflection: 0.1,
                // rotationX: 45,
                // rotationY: 0,
                // rotationZ: 0,
                // shader: "defaults",
                // toggleAxis: false,
                type: "waterPlane",
                // uAmplitude: 0,
                uDensity: 1,
                // uFrequency: 0,
                uSpeed: 0.1,
                uStrength: 1.8,
                // uTime: 0,
                // wireframe: false,

                // control: "query",
                // urlString:
                // "https://www.shadergradient.co/customize?animate=on&axesHelper=off&bgColor1=%23000000&bgColor2=%23000000&brightness=1&cAzimuthAngle=170&cDistance=3.6&cPolarAngle=70&cameraZoom=1&color1=%230019ff&color2=%230029ce&color3=%23ffffff&embedMode=off&envPreset=city&fov=50&gizmoHelper=hide&grain=on&lightType=env&pixelDensity=1&positionX=0&positionY=0.9&positionZ=-0.3&range=disabled&rangeEnd=36.4&rangeStart=0&reflection=0.1&rotationX=45&rotationY=0&rotationZ=0&shader=defaults&toggleAxis=false&type=waterPlane&uAmplitude=0&uDensity=1&uFrequency=0&uSpeed=0.1&uStrength=1.8&uTime=0&wireframe=false",
              },
              style: {
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: -1,
                width: "100%",
                height: "100%",
              },
            });
          })
          .catch((err) => {
            // 处理加载失败的情况
            console.error("Dynamic import failed", err);
          });
      });
    },
  },
  mounted() {
    this.isMobile = window.innerWidth < 950;
    this.$nextTick(() => {
      window.addEventListener(
        "resize",
        _.debounce((e) => {
          this.isMobile = window.innerWidth < 950;
        }),
        150
      );
    });
    document.addEventListener("scroll", this.pageScroll); //监听页面滚动
    if (this.$route.query?.ref) {
      this.scrollTo(this.$route.query?.ref)
    }
    // 监听元素是否在可视区域内
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        this.showBg = entry.isIntersecting;
        if (entry.isIntersecting) {
          this.loadBg();
        }else{
          this.renderShadergradient?.unmount?.();
        }
      });
    }, {});
    observer.observe(this.$refs.container);
  }
};
</script>

<style lang="scss">
.home {
  max-width: 100%;
  min-width: 1200px;
  overflow: hidden;

  .logo-image {
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;

    .image {
      width: 250px;
    }
  }
}

.bottom-button {
  position: fixed;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

  .el-button {
    width: 640px;
    height: 100px;
    border-radius: 100px;
    font-size: 40px;
    color: #fff;
    background: linear-gradient(90deg, #3981f9 13%, #60a4ff 97%);
  }
}

.app-content {
  position: relative;
  padding-top: 110px;
  background-image: linear-gradient(to bottom, #0d10ff 80%, #fff);
  z-index: 0;
  box-sizing: border-box;
}

.app-content__bg {
  // position: absolute;
  // left: 0;
  // right: 0;
  // top: 0;
  // bottom: 0;
}

.case-bg {
  background-image: linear-gradient(to bottom,
      rgba(0, 0, 0, 0) 20%,
      rgba(255, 255, 255, 0.3) 65%,
      #fff);
}
</style>

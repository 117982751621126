<template>
  <div class="card">
    <div class="bg"></div>
    <div class="icon-wrapper">
      <div class="icon first-icon">
        <img :src="firstSvg" />
      </div>
      <div class="icon second-icon">
        <img :src="secondSvg" />
      </div>
    </div>
    <p class="title">{{ title }}</p>
    <p class="text">{{ text }}</p>
  </div>
</template>

<script>
import svgPath from "@/assets/svg/a.svg";
import svgPath2 from "@/assets/svg/aw.svg";
export default {
  props: {
    title: null,
    text: null,
    firstSvg: null,
    secondSvg: null,
  },
  data() {
    return {
      svgPath,
      svgPath2,
    };
  },
};
</script>

<style lang="scss">
@keyframes radius-in {
  0% {
    border-radius: 12px 500px 12px 12px;
  }

  20% {
    border-radius: 12px 450px 12px 12px;
  }

  40% {
    border-radius: 12px 350px 12px 12px;
  }

  60% {
    border-radius: 12px 300px 12px 12px;
  }

  80% {
    border-radius: 12px 200px 12px 12px;
  }

  100% {
    border-radius: 12px 12px 12px 12px;
  }
}

@keyframes radius-out {
  0% {
    border-radius: 12px 12px 12px 12px;
  }

  20% {
    border-radius: 12px 200px 12px 12px;
  }

  40% {
    border-radius: 12px 300px 12px 12px;
  }

  60% {
    border-radius: 12px 350px 12px 12px;
  }

  80% {
    border-radius: 12px 450px 12px 12px;
  }

  100% {
    border-radius: 12px 500px 12px 12px;
  }
}

.card {
  padding: 16px 20px;
  position: relative;
  width: 280px;
  height: 175px;
  border-radius: 12px;
  opacity: 0.9;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(50px);
  overflow: hidden;
  position: relative;
  margin: 0 15px 22px 0px;

  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 340px;
    height: 400px;
    background-color: rgba(21, 94, 239, 1);
    // background-color: #f60;
    transform: scale(0);
    transform-origin: left center;
    transition: transform 0.3s linear;
    animation: 0.3s linear radius-out;
  }

  &:hover {
    .bg {
      transform: scale(1);
      animation: 0.3s linear radius-in;
      z-index: -1;
    }
  }

  .icon-wrapper {
    display: flex;
    flex-direction: column;
    width: 32px;
    height: 32px;
    overflow: hidden;
  }

  .icon {
    img {
      width: 32px;
      height: 32px;
      background-size: contain;
    }

    transition: all 0.2s linear;
  }

  &:hover {
    .first-icon {
      transform: translateY(-100%);
      transform-origin: 50% 50% 0px;
    }

    .second-icon {
      transform: translateY(-100%);
      transform-origin: 50% 50% 0px;
    }
  }

  .title {
    font-size: 16px;
    font-weight: bold;
    color: #192130;
    margin: 15px 0px;
    line-height: 16px;
    transition: all 0.3s;
  }

  .text {
    margin: 0;
    color: #667085;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    transition: all 0.3s;
  }

  &:hover {
    .title {
      color: #fff;
    }

    .text {
      color: #b9d5fe;
    }
  }
}
</style>
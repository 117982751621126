<template>
    <div>
        <div v-animate="{ appearClass: 'animate__fadeInLeft' }" class="content padding-tb-lg animate__animated">
            <div class="text-xsl text-center margin-bottom-xl text-bold padding-top-xl">帮企业“赚钱”的专属大模型AI员工</div>
            <!-- <div class="flex-center padding-top-xl padding-bottom-sm image-view">
                <el-image class="image" fit="cover" :src="image">
                </el-image>
                <el-button @click="openDialog" type="success" class="text-lg"
                style="width: 126px;height: 44px;background-color: #00C198;border-color:#00C198;" round>雇佣</el-button>
            </div> -->
        </div>
        <accordion ref="accordion" :isMobile="isMobile" @openDialog="openDialog"></accordion>

        <div class="employee">
            <div v-animate="{ appearClass: 'animate__fadeInLeft' }"
                class="text-xsl padding-top padding-bottom-xs text-center margin-tb-xl animate__animated">
                <div class="margin-top text-bold">90秒看懂数智员工</div>
            </div>
            <div v-animate="{ appearClass: 'animate__fadeInRight' }" class="employee-video animate__animated">
                <!-- <video class="video" autoplay loop muted :src="video4"></video> -->
                <video-player class="video-player video vjs-custom-skin" ref="videoPlayer" :playsinline="true"
                    :options="playerOptions">
                </video-player>
            </div>
        </div>
        <div class="video-content">
            <div class="content">
                <div v-animate="{ appearClass: 'animate__fadeInLeft' }"
                    class="text-xsl padding-tb-xs text-center margin-tb-xl animate__animated">
                    <div class="margin-top text-bold">基于AI大模型， <br v-if="isMobile" />打造多维度发展的“数智员工”</div>
                </div>
                <div class="flex justify-between padding-top animate__animated text-item">
                    <div v-animate="{ appearClass: 'animate__fadeInLeft' }" class="text-view animate__animated">
                        <div class="text-sl text-bold padding margin-top-sm">秒回复 <span class="text-blue">拒绝让客户等待</span>
                        </div>
                        <div class="text-title flex">7x24小时在线，支持图、文、音频多模态，随时随地服务客户</div>
                        <div class="text-title flex">突破人工接待上限，同时与成千上万客户沟通，不遗漏每条线索</div>
                    </div>
                    <div v-animate="{ appearClass: 'animate__fadeInRight' }" class="video-view animate__animated">
                        <video autoplay loop muted playsinline webkit-playsinline
                            style="margin-top: -6%;margin-left: -60px;" :src="video">
                        </video>
                    </div>
                </div>
                <div class="flex justify-between margin-top-xl text-item">
                    <div v-animate="{ appearClass: 'animate__fadeInLeft' }" class="text-view animate__animated">
                        <div class="text-sl text-bold padding margin-top-sm">"AI"思考 <span
                                class="text-blue">拒绝重复话术</span>
                        </div>
                        <div class="text-title flex">建立知识库，打造企业专属Ai员工大模型</div>
                        <div class="text-title flex">模拟真人沟通，不再是简单的重复话术</div>
                    </div>
                    <div v-animate="{ appearClass: 'animate__fadeInRight' }" class="video-view animate__animated">
                        <video autoplay loop muted playsinline webkit-playsinline
                            style="margin-top: -6%;margin-left: -8%;" :src="video1">
                        </video>
                    </div>
                </div>
                <div class="flex justify-between margin-top-xl text-item">
                    <div v-animate="{ appearClass: 'animate__fadeInLeft' }" class="text-view animate__animated">
                        <div class="text-sl text-bold padding margin-top-sm">更专业 <span class="text-blue">低成本全能型选手</span>
                        </div>
                        <div class="text-title flex">情绪稳定，多平台可接，持续进化，指哪打哪，执行力100%</div>
                        <div class="text-title flex">客户资源永不流失，再也不用担心员工带资源跑路</div>
                    </div>
                    <div v-animate="{ appearClass: 'animate__fadeInRight' }" class="video-view animate__animated">
                        <video autoplay loop muted playsinline webkit-playsinline
                            style="margin-top: -5%;margin-left: -80px;" :src="video2">
                        </video>
                    </div>
                </div>
                <div class="flex justify-between margin-top-xl text-item">
                    <div v-animate="{ appearClass: 'animate__fadeInLeft' }" class="text-view animate__animated">
                        <div class="text-sl text-bold padding margin-top-sm">真主动 <span
                                class="text-blue">定制化SOP促成交</span>
                        </div>
                        <div class="text-title flex">基于聊天记录提炼用户标签，更新用户画像</div>
                        <div class="text-title flex">千人千面发送定制内容，模拟销冠，主动聊单，主动关怀客户</div>
                    </div>
                    <div v-animate="{ appearClass: 'animate__fadeInRight' }" class="video-view animate__animated">
                        <video autoplay loop muted playsinline webkit-playsinline
                            style="margin-top: -6%;margin-left: -8%;" :src="video3">
                        </video>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Accordion from './accordion.vue'
export default {
    components: { Accordion },
    props: { isMobile: { type: Boolean, default: false } },
    data() {
        return {
            image: "https://oss.isuiyou.cn/scrm/41834f51-f74b-4093-b143-c61582ce61da/group21275.png",
            video: "https://oss.isuiyou.cn/scrm/7798bc5a-2f94-4560-a373-ad49e3e051fe/001.mp4",
            video1: "https://oss.isuiyou.cn/scrm/ee08d980-0ae4-48bf-a9bc-92bb03274769/002.mp4",
            video2: "https://oss.isuiyou.cn/scrm/b4f7511c-fdee-4931-93b4-575e4478ff51/003.mp4",
            video3: "https://oss.isuiyou.cn/scrm/f0d033d8-f2fa-4b76-b9ec-b5d2f57a17fe/004.mp4",
            // video4: "https://fcyj-chat.oss-cn-beijing.aliyuncs.com/officialweb/595db27b61287faaea65c018a4854f04.mp4",
            showEmployee: false,
            showTitle: false,
            // 视频
            playerOptions: {
                playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                autoplay: false, // 如果为true,浏览器准备好时开始回放
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 是否视频一结束就重新开始。
                preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: "zh-CN",
                aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [
                    {
                        type: "video/mp4", // 类型
                        src: "https://fcyj-chat.oss-cn-beijing.aliyuncs.com/officialweb/ea49493e3d106c546025939249b09cbe.mp4", // url地址
                    },
                ],
                poster: "https://oss.isuiyou.cn/scrm/6737028d-4f7b-45cf-93c9-f868358d2d84/20240228100939.png", // 封面地址
                notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: false, // 当前时间和持续时间的分隔符
                    durationDisplay: false, // 显示持续时间
                    remainingTimeDisplay: false, // 是否显示剩余时间功能
                    fullscreenToggle: true, // 是否显示全屏按钮
                }
            },
        }
    },
    methods: {
        openDialog() {
            this.$emit("openDialog")
        },
        createAnimate(currentPosition) {
            this.showEmployee = currentPosition > this.$refs.employee.offsetTop;
            this.showTitle = currentPosition > this.$refs.showTitle.offsetTop
            // this.showMainBottomContainer = currentPosition > this.$refs.mainBottomContainer.offsetTop;
            this.$refs.accordion.createAnimate(currentPosition);
        }
    },
}
</script>

<style lang="scss" scoped>
.content {
    width: 1250px;
    margin: 0 auto;

    .image-view {
        position: relative;

        .image {
            width: 1104px;
            margin: 0 auto;
        }

        .el-button {
            position: absolute;
            bottom: 0;
            left: 50%;
            margin-left: -63px;
        }
    }



    .text-title {
        margin-top: 20px;
        width: 415px;
        margin-right: 15px;
        font-size: 20px;

        &::before {
            content: "";
            display: block;
            width: 10px;
            height: 10px;
            margin-top: 6px;
            margin-right: 20px;
            background: url(https://oss.isuiyou.cn/d4fc57ae001c33bf286f7d8836d5ddb042d8e2b9e32855411fe95dfb4409cc3a.png) center center no-repeat;
            background-size: contain;
        }
    }

    .text-view {
        width: 415px;
    }

    .video-view {
        margin-left: 80px;
        min-width: 840px;
        width: 840px;
        height: 560px;
        overflow: hidden;
        border-radius: 38px;
        background-color: #fff;

        video {
            width: 115%;
            height: 115%;
            object-fit: fill;
            z-index: -1;
        }
    }
}

.employee ::v-deep {
    height: 840px;
    background: url(https://oss.isuiyou.cn/9fe162a5e2db8e21291042942d69996655646904ca8e4277bbfd67405e640e5f.png) top center no-repeat;
    background-size: cover;

    .employee-video {
        width: 1024px;
        height: 576px;
        margin: 0 auto;
        display: block;
        box-shadow: 0px 8px 32px 0px rgba(175, 187, 220, 0.5);

        .video-js .vjs-tech video {
            width: 100%;
            height: 100%;
            object-fit: fill;
        }

        .vjs-poster {
            right: 1px;
            background-color: #fff;
        }
    }
}

.video-content {
    background: url(https://oss.isuiyou.cn/ce68eb160e8608c9fbd8272c5e06ecf64225a58ce858b4c1ba2fe98c1e6cb023.png) center 300px no-repeat;
    background-size: contain;
}
</style>
<template>
  <div style="background-color: #0d10ff; min-height: 100vh">
  <!-- <div style="background-color: #ccc; min-height: 100vh"> -->
    <div style="height: 100vh"></div>
    <can-do style="margin: auto" />
    <Model style="margin: auto" />
    <echoes />
  </div>
</template>

<script>
import CanDo from "@/components/can-do";
import Model from "@/components/model/index";
import Echoes from "@/components/echoes";

export default {
  components: { CanDo, Model, Echoes },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
</style>
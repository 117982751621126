<template>
    <div>
        <el-drawer custom-class="drawer-view" size="53%" v-if="isMobile" :visible.sync="visible" direction="btt"
            @close="reset" :before-close="close">
            <div class="drawer-form"></div>
            <el-form ref="form" :rules="rules" label-position="top" :model="form">
                <el-form-item label="姓名" prop="name">
                    <el-input placeholder="请输入姓名" maxlength="11" v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                    <el-input placeholder="请输入手机号" maxlength="11" v-model="form.phone"></el-input>
                </el-form-item>
                <el-form-item label="公司名" prop="companyName">
                    <el-input placeholder="公司名" maxlength="50" v-model="form.companyName"></el-input>
                </el-form-item>
                <el-form-item label="私域好友规模" prop="scale">
                    <el-select v-model="form.scale" style="width: 100%;" popper-class="select-popper-class" placeholder="请将您的私域规模告知我们">
                        <el-option label="1-100" value="1-100"></el-option>
                        <el-option label="100-500" value="100-500"></el-option>
                        <el-option label="500-1000" value="500-1000"></el-option>
                        <el-option label="1000-5000" value="1000-5000"></el-option>
                        <el-option label="5000-10000" value="5000-10000"></el-option>
                        <el-option label="10000以上" value="10000以上"></el-option>
                    </el-select>
                </el-form-item>
                <div>
                    <el-button type="primary" style="width: 100%;" @click="onSubmit">提交</el-button>
                    <div class="margin-tb-sm text-center">咨询顾问会尽快与您取得联系</div>
                </div>
            </el-form>
        </el-drawer>
        <el-dialog v-else custom-class="index-dialog" width="750px" top="22vh" @close="reset"
            :close-on-click-modal="false" :close-on-press-escape="false" :visible.sync="visible" :before-close="close">
            <div class="dialog-content flex">
                <div class="left padding ">
                    <div class="text-center text-xl text-black">你可以获得</div>
                    <div class="padding-left margin-left-xs">
                        <div class="flex margin-top margin-bottom-sm">
                            <i class="icon-first"></i>
                            <span class="text-lg padding-left-xs padding-top-xs text-blue">专属大模型</span>
                        </div>
                        <div class="text-title flex">专属AI大模型员工帮训</div>
                        <div class="text-title flex">知识库语料库完善升级</div>
                        <div class="flex margin-top margin-bottom-sm">
                            <i class="icon-second"></i>
                            <span class="text-lg padding-left-xs padding-top-xs text-blue">专业陪跑</span>
                        </div>
                        <div class="text-title flex">高级客成专家实战陪跑</div>
                        <div class="text-title flex">后台系统落地包教包会</div>
                        <div class="flex margin-top margin-bottom-sm">
                            <i class="icon-third"></i>
                            <span class="text-lg padding-left-xs padding-top-xs text-blue">行业案例</span>
                        </div>
                        <div class="text-title flex">100+企业真实运营案例</div>
                        <div class="text-title flex">AIGC行业情报实时更新</div>
                    </div>
                </div>
                <div class="right">
                    <!-- <i class="icon-logo"></i> -->
                    <div class="flex-center margin-top-sm margin-bottom-xs justify-center">
                        <div class="real-bo margin-left-sm"></div>
                        <div class="logo"></div>
                    </div>
                    <el-form ref="form" :rules="rules" :model="form">
                        <el-form-item label="姓名" prop="name">
                            <el-input placeholder="请输入姓名" maxlength="11" v-model="form.name"></el-input>
                        </el-form-item>
                        <el-form-item prop="companyName" label="公司名称">
                            <el-input placeholder="请输入公司名" maxlength="50" v-model="form.companyName"></el-input>
                        </el-form-item>
                        <el-form-item prop="phone" label="手机号">
                            <el-input placeholder="请输入手机号" maxlength="11" v-model="form.phone"></el-input>
                        </el-form-item>
                        <el-form-item label="私域好友规模" prop="scale">
                            <el-select v-model="form.scale" style="width: 100%;" popper-class="select-popper-class" placeholder="请将您的私域规模告知我们">
                                <el-option label="5k以下" value="5k以下"></el-option>
                                <el-option label="5k-3w" value="5k-3w"></el-option>
                                <el-option label="3w-10w" value="3w-10w"></el-option>
                                <el-option label="10w-50w" value="10w-50w"></el-option>
                                <el-option label="50w-100w" value="50w-100w"></el-option>
                                <el-option label="100w以上" value="100w以上"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-button type="primary" class="margin-top" style="width: 100%;" @click="onSubmit">提交</el-button>
                        <div class="margin-tb-sm text-center">咨询顾问会尽快与您取得联系</div>
                        <!-- <div class="flex-center justify-end">
                            <div class="flex-center text-sm">
                                <span style="width: 45px;">登录</span>
                                <i class="icon-bo"></i><el-button type="text"
                                    style="width: 100%;text-align: left;vertical-align: middle;"
                                    @click="toScrm">Real-bot后台账号
                                </el-button>
                            </div>
                        </div> -->
                    </el-form>

                </div>

            </div>
        </el-dialog>
        <el-dialog :custom-class="`index-dialog ${isMobile ? 'mobile' : ''}`" width='300px' top="32vh"
            :close-on-press-escape="false" :visible.sync="dialogVisible" :show-close="false">
            <div class="dialog text-center">
                <div><i class="el-icon-success"></i></div>
                <div class="grey flex-center margin-left-xs margin-top-xs">
                    <el-statistic format="s" @finish="hilarity" :value="deadline" time-indices
                        title="提交成功，我们将在24小时内联系您">
                        <template slot="suffix">
                            秒后自动关闭
                        </template>
                    </el-statistic>

                </div>
            </div>

        </el-dialog>
    </div>
</template>

<script>
import { websiteSave } from "@/api/index.js"
import CountTo from 'vue-count-to'
export default {
    components: {
        CountTo
    },
    props: { isMobile: { type: Boolean, default: false } },
    data() {
        return {
            visible: false,
            dialogVisible: false,
            form: {
                name: "",
                phone: "",
                companyName: "",
                messageBoard: "",
                scale: ""
            },
            rules: {
                name: [{required: true, message: "请输入姓名", trigger: "blur" }],
                phone: [{ required: true, message: "请输入手机号码", trigger: "blur" }, {
                    pattern: /^(?:(?:\+|00)86)?1(?:3[\d]|4[5-79]|5[0-35-9]|6[5-7]|7[0-8]|8[\d]|9[189])\d{8}$/,
                    message: "请输入正确的手机号码",
                    trigger: "blur"
                }],
                companyName: [{ required: true, message: "请输入公司名称", trigger: "blur" }, {
                    pattern: /.{2,}/,
                    message: "公司名称长度必须为2~50字符",
                    trigger: "blur"
                }],
                scale: [{ required: true, message: "请选择私域好友规模", trigger: "blur" }],
            },
            deadline: "",
        }
    },
    methods: {
        open() {
            this.visible = true;
        },
        onSubmit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    websiteSave(this.form).then(res => {
                        if (res.data.code == 0) {
                            this.deadline = Date.now() + 1000 * 4;
                            this.dialogVisible = true;
                        }
                    })
                }
            })
        },
        hilarity() {
            this.visible = false;
            this.dialogVisible = false;
        },
        toScrm() {
            this.visible = false;
            window.open("https://console.real-bot.cn/login")
        },
        close(done) {
            if (this.isMobile) {
                done();
                return;
            }
            this.$confirm('你可能会错过一个最会赚钱的数智员工', '确定离开吗？', {
                confirmButtonText: '离开',
                cancelButtonText: '去填写',
                customClass: this.isMobile ? 'mobile' : 'a',
                type: 'warning'
            }).then(() => {
                done();
            }).catch(() => {
            })
        },
        reset() {
            this.form = {
                phone: "",
                companyName: "",
                customerNumber: ""
            };
            this.$refs.form.resetFields();
        }
    },
}
</script>

<style lang="scss" scoped>
.dialog-content {
    border-radius: 20px;

    .left {
        width: 375px;
        height: 500px;
        background: linear-gradient(116deg, rgba(212, 225, 255, 0.9882) -3%, #F4F8FF 81%);
    }

    .right ::v-deep {
        width: 375px;
        padding: 0 30px;

        .el-input__inner{
            height: 32px;
            background: #F2F3F5;
        }

        .el-form-item {
            margin-bottom: 16px;
            
        }
        .el-form-item__label{
            line-height: 20px;
        }
    }

    .icon-first {
        width: 52px;
        height: 32px;
        display: block;
        background: url(https://oss.isuiyou.cn/79e42b9b875423a444369122bba3b7082a07bfc714cd51d7fa1c50eb61834225.png) center center no-repeat;
        background-size: contain;
    }

    .icon-second {
        width: 52px;
        height: 32px;
        display: block;
        background: url(https://oss.isuiyou.cn/5133e0238626666a3788be8f1021b726e7c1e9b1d5ec3a21f9eb57f01f72c2f6.png) center center no-repeat;
        background-size: contain;
    }

    .icon-third {
        width: 52px;
        height: 32px;
        display: block;
        background: url(https://oss.isuiyou.cn/4a3681681c738a517203bad3d512fa44d95ed521402fc794c85f9a3800f777a4.png) center center no-repeat;
        background-size: contain;
    }

    .icon-logo {
        width: 182px;
        height: 46px;
        display: block;
        margin: 20px auto;
        background: url(https://oss.isuiyou.cn/74a944cbb9cb578d33b0a58bc90163a56c969698aa3b29dda1b6367bb65b605e.png) center center no-repeat;
        background-size: contain;
    }

    .icon-bo {
        width: 22px;
        height: 19px;
        display: block;
        background: url(https://oss.isuiyou.cn/51a9f3fdafcfe8bc48d29dff90bb710c99656065a0bf3dbad3f5cd37de7d5639.png) center center no-repeat;
        background-size: contain;
    }

    .real-bo {
        width: 48px;
        height: 41px;
        background: url(https://oss.isuiyou.cn/scrm/dbc1b6f7-d170-46ba-abc5-e5f10b2b40b8/real-bo.png) center center no-repeat;
        background-size: contain;
    }

    .logo {
        width: 131px;
        height: 35px;
        background: url(https://oss.isuiyou.cn/scrm/97741b56-de1e-4b6d-abce-342bec7eb2e9/logo.png) center center no-repeat;
        background-size: contain;
    }

    .el-button--primary {
        background: linear-gradient(285deg, #5DA2FE 13%, #3981F8 89%);
    }

    .text-title {
        margin-top: 10px;
        width: 400px;
        margin-right: 15px;
        margin-left: 5px;

        &::before {
            content: "";
            display: block;
            width: 13px;
            height: 13px;
            margin-top: 4px;
            margin-right: 20px;
            background: url(https://oss.isuiyou.cn/d4fc57ae001c33bf286f7d8836d5ddb042d8e2b9e32855411fe95dfb4409cc3a.png) center center no-repeat;
            background-size: contain;
        }
    }
}

.drawer-form {
    background: linear-gradient(180deg, #EFF8FF 2%, #EFF8FF 62%, #FDFEFF 100%);
}

.dialog ::v-deep {
    height: 180px;
    padding: 28px 30px;

    .head {
        font-size: 14px;
        font-weight: bold;
    }

    .el-statistic .con,
    .el-statistic .con .number {
        font-size: 13px;
        color: #6F7C95;
        padding: 0;
    }

    .el-statistic .con {
        justify-content: center;
        margin-top: 10px;
    }

}
</style>
<template>
  <el-popover placement="right-end" width="200" trigger="hover">
    <div class="padding-xs padding-bottom-sm">
      <el-image
        src="https://oss.isuiyou.cn/bef3203e03c42731e8315d918470a70d24a580075d089c72598fc4c6dbd0d732.jpg"></el-image>
        <div class="text-center">扫码获取企业服务</div>
    </div>
    <el-button class="weChatBtn" slot="reference">
      <i class="icon-wechat"></i>
    </el-button>
  </el-popover>
</template>

<script>
export default {
  data() {
    return {

    }
  },
}
</script>

<style lang="scss" scoped>
.weChatBtn {
  position: fixed;
  right: 60px;
  bottom: 100px;
  width: 63px;
  height: 63px;
  background-color: #0C0C19;
  border-radius: 50%;
  border-color: #0C0C19;
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }

  .icon-wechat {
    width: 24px;
    height: 24px;
    display: block;
    background: url(https://oss.isuiyou.cn/e0fcd9c98013cd46ef00c7b9f567df0bb54744bf758142072196a24eefd43273.png) center center no-repeat;
    background-size: contain;
  }
}
</style>
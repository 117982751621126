<template>
  <div class="model">
    <h1>基于AI大模型，打造多维度发展的“数智员工”</h1>
    <el-row :gutter="20">
      <el-col :xs="24" :md="12">
        <Model1 class="model-view" ref="model1Ref" :style="leftStyle" />
      </el-col>
      <el-col :xs="24" :md="12">
        <Model2 class="model-view" ref="model2Ref" :style="rightStyle" />
      </el-col>
      <el-col :xs="24" :md="12">
        <Model3 class="model-view" ref="model3Ref" :style="leftStyle2" />
      </el-col>
      <el-col :xs="24" :md="12">
        <Model4 class="model-view" ref="model4Ref" :style="rightStyle2" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { debounce } from "throttle-debounce";
import Model1 from "./model1.vue";
import Model2 from "./model2.vue";
import Model3 from "./model3.vue";
import Model4 from "./model4.vue";

const leftStyle = {
  opacity: 1,
  transform: `perspective(1200px) translateX(0px) translateY(0px) scale(1) rotate(0deg) rotateX(0deg) rotateY(0deg) skewX(0deg) skewY(0deg) translateZ(0px)`,
  transition: "transform .3s, opacity .3s",
};
const defaultLeftStyle = {
  opacity: 0.6,
  transform: `perspective(1200px) translateX(-48px) translateY(48px) scale(1) rotate(-8deg) rotateX(8deg) rotateY(8deg) skewX(0deg) skewY(0deg) translateZ(0px)`,
  transition: "transform .3s, opacity .3s",
};

const rightStyle = {
  opacity: 1,
  transform: `perspective(1200px) translateX(0px) translateY(0px) scale(1) rotate(0deg) rotateX(0deg) rotateY(-0deg) skewX(0deg) skewY(0deg) translateZ(0px)`,
  transition: "transform .3s, opacity .3s",
};
const defaultRightStyle = {
  opacity: 0.7,
  transform: `perspective(1200px) translateX(48px) translateY(78px) scale(1) rotate(7deg) rotateX(14deg) rotateY(-13deg) skewX(0deg) skewY(0deg) translateZ(0px)`,
  transition: "transform .3s, opacity .3s",
};

export default {
  name: "Model",
  components: {
    Model1,
    Model2,
    Model3,
    Model4,
  },
  props: ["isMobile"],
  data() {
    return {
      leftStyle: { ...defaultLeftStyle },
      leftStyle2: { ...defaultLeftStyle },
      rightStyle: {
        ...defaultRightStyle,
      },
      rightStyle2: {
        ...defaultRightStyle,
      },
    };
  },
  methods: {
    handleScroll() {
      if (this.isMobile) return;
      this.handleLeft();
      this.handleRight();
    },
    handleLeft() {
      const dom1 = this.$refs.model1Ref?.$el;
      const dom3 = this.$refs.model3Ref?.$el;
      this.leftStyle = this.getLeftStyle(dom1);
      this.leftStyle2 = this.getLeftStyle(dom3);
    },
    handleRight() {
      const dom2 = this.$refs.model2Ref?.$el;
      const dom4 = this.$refs.model4Ref?.$el;
      this.rightStyle = this.getRightStyle(dom2);
      this.rightStyle2 = this.getRightStyle(dom4);
    },
    getLeftStyle(dom) {
      const { top } = dom.getBoundingClientRect();
      const innerHeight = window.innerHeight;
      const offsetHeight = dom.offsetHeight;
      if (top <= innerHeight && top >= innerHeight - offsetHeight) {
        const diff = Math.abs(innerHeight - top);
        let ratio = Math.min(diff / offsetHeight, 1);
        ratio = 1 - ratio;
        const translateValue = 48 * ratio;
        const rotateValue = 8 * ratio;
        const leftStyle = {
          opacity: 0.6 + 0.4 * (1 - ratio),
          transform: `perspective(1200px) translateX(-${translateValue}px) translateY(${translateValue}px) scale(1) rotate(-${rotateValue}deg) rotateX(${rotateValue}deg) rotateY(${rotateValue}deg) skewX(0deg) skewY(0deg) translateZ(0px)`,
          transition: "transform .3s, opacity .3s",
        };
        return leftStyle;
      } else if (top > innerHeight) {
        return {
          ...defaultLeftStyle,
        };
      } else {
        return {
          ...leftStyle,
        };
      }
    },
    getRightStyle(dom) {
      const { top } = dom.getBoundingClientRect();
      const innerHeight = window.innerHeight;
      const offsetHeight = dom.offsetHeight;
      if (top <= innerHeight && top >= innerHeight - offsetHeight) {
        const diff = Math.abs(innerHeight - top);
        let ratio = Math.min(diff / offsetHeight, 1);
        ratio = 1 - ratio;
        const translateXValue = 48 * ratio;
        const translateYValue = 78 * ratio;
        const rotateValue = 7 * ratio;
        const rotateXValue = 14 * ratio;
        const rotateYValue = 13 * ratio;
        const leftStyle = {
          opacity: 0.7 + 0.4 * (1 - ratio),
          transform: `perspective(1200px) translateX(-${translateXValue}px) translateY(${translateYValue}px) scale(1) rotate(${rotateValue}deg) rotateX(${rotateXValue}deg) rotateY(-${rotateYValue}deg) skewX(0deg) skewY(0deg) translateZ(0px)`,
          transition: "transform .3s, opacity .3s",
        };
        return leftStyle;
      } else if (top > innerHeight) {
        return {
          ...defaultRightStyle,
        };
      } else {
        return {
          ...rightStyle,
        };
      }
    },
  },
  mounted() {
    this.debounceFunc = debounce(3, this.handleScroll, { atBegin: false });
    window.addEventListener("scroll", this.debounceFunc);
  },
  destroyed() {
    window.removeEventListener("scroll", this.debounceFunc);
  },
};
</script>

<style lang="scss">
.model {
  width: 1360px;
  padding-top: 108px;
  display: flex;
  flex-wrap: wrap;

  .model-view {
    margin-bottom: 25px;
  }

  h1 {
    font-size: 58px;
    font-weight: 900;
    line-height: 80px;
    text-align: center;
    margin: 0 auto;
    color: #ffffff;
    margin-bottom: 60px;
  }

  &-mrb {
    margin-right: 30px;
    margin-bottom: 30px;
  }
}
</style>
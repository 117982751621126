import request from '@/assets/js/http.js'
// 获取角色列表
export function websiteSave(data) {
    return request({
        url: '/official/website/save',
        method: 'post',
        data
    })
}


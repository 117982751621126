<template>
  <div class="appMain-content">
    <div class="appMain justify-center flex-center text-center">
      <div class="marin-top-container">
        <div :key="key" :class="[{ mobile: isMobile }]"
          class="main-top-text flex-center flex-wrap justify-center text-bold animate__animated animate__fadeInLeft">
          <VueTyper text='Real-bot，为你提供' :typeDelay="150" @completed="completed" :repeat="0"></VueTyper>
          <br v-if="isMobile" />
          <VueTyper class="right-text margin-left" erase-style='backspace' :type-delay='150' :pre-type-delay='1000'
            @completed="completedRight" v-if="showAfter" :text='["数智销售", "数智客服", "数智讲师", "数智导购员", "数智物业助手", "数智宠物管家"]'
            :repeat="0">
          </VueTyper>
        </div>
        <div class="button animate__animated animate__fadeInUp" v-if="active" style="width: 100%;">

          <div class="margin-bottom-lg margin-top text-xl text-black">大模型AI员工，以数智人力解放生产力</div>
          <div class="padding-top-xs" style="width: 100%;">
            <el-button @click="openDialog" class="btn text-lg text-blue solid radius-8 line-blue margin-left-sm" plain>
              <i class="icon-detail"></i> 了解详情
            </el-button>
          </div>
        </div>
      </div>
      <div class="main-left-img animate__animated animate__fadeInUp"></div>
      <div class="main-right-img animate__animated animate__fadeInUp"></div>
    </div>
    <div v-if="!isMobile" class="main-bottom-container flex-center animate__animated animate__fadeInUp">

      <vue-seamless-scroll class="seamless" :class-option="items.classOption" v-for="(items, index) in list"
        :data="items.item" :key="index">
        <img class="image" v-for="item in items.item" :key="item.name" :src="item.url" alt="">
      </vue-seamless-scroll>
    </div>
  </div>
</template>

<script>
import CountTo from 'vue-count-to'
import { VueTyper } from 'vue-typer';
import vueSeamlessScroll from 'vue-seamless-scroll'
export default {
  components: {
    CountTo,
    VueTyper,
    vueSeamlessScroll
  },
  props: { isMobile: { type: Boolean, default: false } },
  data() {
    return {
      showMainBottomContainer: false,
      showAfter: false,
      active: false,
      key: "",
      list: [{
        item: [{ name: 'weixin', url: 'https://oss.isuiyou.cn/52b3db4959c734f62985ac73687691ba355379848ddb05b1eb24b64cab333a92.png' },
        { name: 'xiaohongshu', url: 'https://oss.isuiyou.cn/de22c1a67ce4a38cae511441276302f323cb020ff95048610a2aa954cd51ad72.png' }],
        classOption: { limitMoveNum: 2, singleHeight: 70, waitTime: 3000, hoverStop: false }
      },
      {
        item: [{ name: 'tongyi', url: 'https://oss.isuiyou.cn/582f517e697330a86b8725d6132884bf94d2095a226fef476c6cfecd3353347f.png' },
        { name: 'qiwei', url: 'https://oss.isuiyou.cn/8e6b01705e7405a94381e82b0ba90273ae95dccf2f8b8d033160d36ca54252c5.png' }],
        classOption: { limitMoveNum: 2, singleHeight: 70, waitTime: 3030, hoverStop: false }
      },
      {
        item: [{ name: 'douyin', url: 'https://oss.isuiyou.cn/dfce31a498cb8ec47db8751cd62d3583a71301dc5b4532dce40fa1788622b8dc.png' },
        { name: 'openAI', url: 'https://oss.isuiyou.cn/57e398556f78b880dab750270d7427c7ac78027226dd9c15ba2f08ba0a8dd6f5.png' }],
        classOption: { limitMoveNum: 2, singleHeight: 70, waitTime: 3060, hoverStop: false }
      },
      {
        item: [{ name: 'kuaishou', url: 'https://oss.isuiyou.cn/5caaeed2f8f86f610016b810b3f81531fbe113350d5ae4dbfe5a274a7db54077.png' },
        { name: 'line', url: 'https://oss.isuiyou.cn/c1a0edcad01f1be206a9d779706d24d994acc8c47dfb34b4d10b7801e7ba83e7.png' }],
        classOption: { limitMoveNum: 2, singleHeight: 70, waitTime: 3090, hoverStop: false }
      },
      {
        item: [{ name: 'whats', url: 'https://oss.isuiyou.cn/437e5f06300bf026f34a5aea3699fbcd685e2465a3ecceaf1138405d9c230d58.png' },
        { name: 'facebook', url: 'https://oss.isuiyou.cn/105a2f90b3ff3c2b23dd23d25509cfb64e8c5ca3c926a012b2918e385ae7eec4.png' }],
        classOption: { limitMoveNum: 2, singleHeight: 70, waitTime: 3120, hoverStop: false }
      }
      ],

    }
  },
  methods: {
    openDialog() {
      this.$emit("openDialog")
    },
    completed() {
      this.showAfter = true;
      this.active = true;
    },
    completedRight() {
      setTimeout(() => {
        this.showAfter = false;
        this.key = new Date().getTime();
      }, 1000);
    },
    createAnimate(currentPosition) {
      // this.showMainBottomContainer = currentPosition > this.$refs.mainBottomContainer.offsetTop
    }
  }
}
</script>

<style lang="scss" scoped>
.appMain-content {
  height: calc(100vh + 1px);
  min-height: 765px;

  .marin-top-container ::v-deep {
    height: 320px;

    .caret.vue-typer-caret-blink {
      width: 6px;
      background-color: #3981F8;
    }

    .right-text {
      position: relative;
      word-spacing: 5px;
      display: inline-block;
      line-height: 1;
      white-space: nowrap;
      color: transparent;
      background: linear-gradient(90deg, #8C28F1 5%, #CC28F1 31%, #CC28F1 53%, #C11F1F 77%);
      background-clip: text;
      background-position: 50% 0;

      .typed {
        color: transparent;
      }

      // &::after {
      //   content: attr(data-text);
      //   position: absolute;
      //   left: 0;
      //   top: 0;
      //   width: 100%;
      //   height: 100%;
      //   z-index: 5;
      //   background-clip: text;
      //   background-size: 150% 100%;
      //   background-repeat: no-repeat;
      //   animation: shine 1s 1 linear 0.5s;
      // }

      // @keyframes shine {
      //   0% {
      //     background-image: linear-gradient(120deg, transparent 0%, transparent 5%, white 11%, transparent 11.15%, transparent 15%, transparent 100%);
      //     background-clip: text;
      //     background-position: 50% 0;
      //   }

      //   100% {
      //     background-image: linear-gradient(120deg, transparent 0%, transparent 5%, white 11%, transparent 11.15%, transparent 15%, transparent 100%);
      //     background-clip: text;
      //     background-position: -190% 0;
      //   }
      // }

      // &:after {
      //   position: absolute;
      //   content: attr(data-text);
      //   color: #000;
      //   height: 100%;
      //   left: 0;
      //   top: 0;
      //   width: 100%;
      //   white-space: nowrap;
      //   overflow: hidden;
      //   background: linear-gradient(90deg, #8C28F1 5%, #CC28F1 31%, #CC28F1 53%, #C11F1F 77%);
      //   -webkit-background-clip: text;
      //   -webkit-text-fill-color: transparent;
      //   // transition: all 3s;
      //   // animation: textBackground 0.3s linear 0.55s;

      // }

      // @keyframes textBackground {
      //   0% {
      //     background: linear-gradient(90deg, #8C28F1 5%, #CC28F1 31%, #CC28F1 53%, #C11F1F 77%);
      //     -webkit-background-clip: text;
      //     -webkit-text-fill-color: transparent;
      //   }

      //   5% {
      //     background: linear-gradient(90deg, #8C28F1 5%, #000 31%, #000 53%, #000 77%);
      //     -webkit-background-clip: text;
      //     -webkit-text-fill-color: transparent;
      //   }

      //   50% {
      //     background: linear-gradient(90deg, #8C28F1 5%, #CC28F1 31%, #000 53%, #000 77%);
      //     -webkit-background-clip: text;
      //     -webkit-text-fill-color: transparent;
      //   }

      //   75% {
      //     background: linear-gradient(90deg, #8C28F1 5%, #CC28F1 31%, #CC28F1 53%, #000 77%);
      //     -webkit-background-clip: text;
      //     -webkit-text-fill-color: transparent;

      //   }

      //   100% {
      //     background: linear-gradient(90deg, #8C28F1 5%, #CC28F1 31%, #CC28F1 53%, #C11F1F 77%);
      //     -webkit-background-clip: text;
      //     -webkit-text-fill-color: transparent;

      //   }
      // }



    }
  }

  .main-bottom-container {
    width: 1160px;
    margin: 0px auto 0;

    .seamless {
      height: 70px;
      overflow: hidden;

      .image {
        width: 217px;
        height: 70px;
      }
    }

  }

  .button {
    // display: none;
  }

  .ml-10 {
    margin-left: -8px;
  }

  .mb-100 {
    // margin-bottom: -150px;
  }

  .appMain {
    height: calc(100vh - 100px);
    min-height: 600px;

    // background: url(https://oss.isuiyou.cn/scrm/b831dd32-13c7-4ceb-8c07-ed100ea3165e/4d69450a241b2853f71026e12c493a82469e89b234ed9-c0NfbS.jpg) center center no-repeat;
    // background-size: cover;
    .main-right-img {
      position: absolute;
      right: 200px;
      bottom: 150px;
      width: 480px;
      height: 480px;
      z-index: -1;
      background: url(https://oss.isuiyou.cn/725e6000a9e0584d63a29eb3916c0ef19768db15567039a702a856f6c6b28676.png) center center no-repeat;
      background-size: contain;
    }

    .main-left-img {
      position: absolute;
      left: 200px;
      z-index: -1;
      width: 452px;
      height: 646px;
      background: url(https://oss.isuiyou.cn/f4cc1d2e3aab16a61edd386fac83e1d46d65fa3d49081d610f6e9303444da40e.png) center center no-repeat;
      background-size: contain;
    }

    .logo {
      width: 295px;
      height: 80px;
      display: inline-block;
      vertical-align: middle;
      background: url(https://oss.isuiyou.cn/scrm/97741b56-de1e-4b6d-abce-342bec7eb2e9/logo.png) center center no-repeat;
      background-size: contain;
    }

    .main-top-text {
      font-size: 80px;
      font-weight: 500;
      color: #1B3863;
      font-family: 'Alibaba PuHuiTi 3.0';

      &.mobile {
        font-size: 75px;
      }
    }

    .btn {
      width: 255px;
      height: 60px;
      border-radius: 390px;
      color: #fff;
      font-size: 20px;
      background: #000000;
      box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.16);

      &:hover {
        border-color: #000;
      }
    }

    .el-button--primary {
      background: linear-gradient(285deg, #5DA2FE 13%, #3981F8 89%);
    }
  }
}
</style>
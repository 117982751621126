<template>
  <div class="echoes">
    <h3 v-animate="{ appearClass: 'animate__fadeInUp' }" class="animate__animated">用户之声</h3>
    <el-row :gutter="10" v-animate="{ appearClass: 'animate__fadeInUp' }" class="animate__animated echoes-up">
      <el-col :xs="24" :md="8" class="echoes-item">
        <div class="echoes-box">
          <p>
            看到这个产品的当下，就是激动， 因为我们每年花在客服人员上的
            成本太多了！
          </p>
          <div class="echoes-box__ai"></div>
        </div>

      </el-col>
      <el-col :xs="24" :md="8" class="echoes-item">
        <div class="echoes-box echoes-box__black">
          <p class="white">这太棒了，今天全公司出门团建，</p>
          <p class="white">AI员工自己在家接单子，</p>
          <p class="white">你懂这个感觉吗？</p>
          <p class="white">以前从来没有过！</p>
          <div class="echoes-box__nvwo"></div>
        </div>
      </el-col>
    </el-row>
    <div v-animate="{ appearClass: 'animate__fadeInUp' }" class="animate__animated echoes-down">
      <el-row>
        <el-col :xs="24" :md="8">
          <div class="echoes-down__left">
            <p>感谢各位伙伴的高度认可，我们一定不负期待，笃志前行！</p>
          </div>
        </el-col>
        <el-col :xs="24" :md="16">
          <div class="echoes-down__right">
            <div class="echoes-down__right-wrapper">
              <div class="top-bg"></div>
              <vue-seamless-scroll :data="data" class="warp" v-for="(data, index) in listData"
                :classOption="{ limitMoveNum: 2, direction: index == 1 ? 0 : 1, step: 0.3 }" :key="index">
                <ul class="list">
                  <li class="item" v-for="(item, index) in data" :key="index">
                    <p class="text">{{ item.title }}</p>
                    <div class="info-wrapper">
                      <div class="info-box">
                        <img style="width: 24px; height: 24px" :src="item.url" fit="cover" alt="">
                        <!-- <el-image style="width: 24px; height: 24px" :src="item.url" fit="cover" /> -->
                        <span>{{ item.name }}</span>
                      </div>
                      <el-rate v-model="item.rate" disabled text-color="#ff9900" />
                    </div>
                  </li>
                </ul>
              </vue-seamless-scroll>
              <div class="bottom-bg"></div>
            </div>
          </div>
        </el-col>
      </el-row>

    </div>
  </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";

const list1 = [
  {
    title:
      "看到这个产品的当下，就是激动，因为我们每年花在客服人员上的成本太多了！",
    rate: 5,
    name: "残月星空245",
    url: "https://oss.isuiyou.cn/4557a43f58a338e4ecbeb66eb86291213351e83d6948979d3d5e57ca2ccd2c82.png",
  },
  {
    title:
      "这太棒了，今天全公司出门团建，AI员工自己在家接单子，你懂这个感觉吗？以前从来没有过",
    rate: 5,
    name: "Kasnars",
    url: "https://oss.isuiyou.cn/f4449e4b59cbcd7c7234a45e02988e20f3455d597f2a3044254bb4a5e73a9e6f.png",
  },
  {
    title:
      "我们买过好几款scrm工具，真的用起来其实很难，这个Real-bot训练完就能用，使用很友好，虽然需要训练，但是完全不用我们操心，信人直接训练好了交付给我们，关键是还便宜，性价比很高！",
    rate: 5,
    name: "我是阿岩哎",
    url: "https://oss.isuiyou.cn/a2c11c7f7dad5cb56d4b20a5b1fcb7021cb7a042f63ed262caffd4e884055e29.png",
  }
];

const list2 = [
  {
    title:
      "你们这服务太周到了，小模型都不用我们动手训练的",
    rate: 5,
    name: "toyehuang",
    url: "https://oss.isuiyou.cn/f618ac170e53032d92d9131cc196ac88e25a4fb852bd11c77aba05aaa27add25.png",
  },
  {
    title:
      "各家大模型井喷式爆发，我们只需要把最适合自己企业的大模型找出来，信人直接对接调用，现在就感觉所有大厂都在为我服务！他们越卷，我的效果就越好，一个字“爽”。",
    rate: 5,
    name: "我的棒棒你...",
    url: "https://oss.isuiyou.cn/efa93d834218a8dbe9245e5af2bca0de72cfc98915a16e15ad6991a6ca4e6858.png",
  },
  {
    title:
      "我有50个账号，每个账号里5千多个客户，但将近80%都是沉默的，以往都是通过群发、广告， 想把这群客户唤醒激活，实际上我自己都不会为群发广告买单，更何况我的客户，现在好了，AI员工自动帮我聊，我只需要坐等付费哈哈哈。",
    rate: 5,
    name: "爱篮球爱背...",
    url: "https://oss.isuiyou.cn/3fe0deb99a54ef1124034495dbf983262a663e20cc9143eadbf20126721f3ad9.png",
  }
];

const list3 = [
  {
    title:
      "已经考虑增购了，这是不是对你们最高的评价？",
    rate: 5,
    name: "Fansr_",
    url: "https://oss.isuiyou.cn/954e0c5af560fc88eeff9051c4971c5c69c8a74c675de58a663cc8575059214d.png",
  },
  {
    title:
      "我们线下门店其实最希望客户能到店，也最关注到店率，但是我不可能专门设一个部门去邀请客户，第一工作不会饱和的，第二这个工作有方法论，也有技巧，所以我一直在找合适的产品帮我做这件事，Real-bot完美帮我解决这个问题，而且还能兼职客服和售后，我不仅不用专门设置部门，甚至还能节省2-3个人力。",
    rate: 5,
    name: "隔壁村徐大爷",
    url: "https://oss.isuiyou.cn/34cbb7f5ed6cb4d294985c97fe96b891113d5eaa5d131e8d74fc59058e6def19.png",
  },
  {
    title:
      "我来说一个吧，对我们公司来说，最好用的就是信息采集，不用员工一个个去问，直接给出满分答案。",
    rate: 5,
    name: "songlongfee",
    url: "https://oss.isuiyou.cn/732dd8126b801a417fdbe3e2cf2d982ac117c37a2095c3dbbc93f34387310071.png",
  },
  {
    title:
      "功能少而精，把我真正需要的功能全都做出来了，完美契合我们公司的需求，别人还在体验各种各样的大模型，我已经用它来赚钱了，这种先人一步的感觉比一天赚一百万还激动。",
    rate: 5,
    name: "西瓜椰椰",
    url: "https://oss.isuiyou.cn/69a53fc465ad792ae44e7b73ddef82b22efc250c94d24c00d6ffcdf3842b8c9f.png",
  },
];

export default {
  name: "Echoes",
  components: {
    vueSeamlessScroll,
  },
  data() {
    return {
      listData: [list1, list2, list3],
    };
  },
};
</script>

<style lang="scss" scoped>
.echoes {
  max-width: 98%;
  min-height: 1287px;
  border-radius: 50px;
  background: #eff5ff;

  &-item {
    width: 675px;
  }

  h3 {
    font-size: 58px;
    font-weight: 900;
    line-height: 72px;
    text-align: center;
    margin: 0 0 50px 0;
    padding-top: 85px;
  }

  &-up {
    display: flex;
    justify-content: center;
  }

  &-down {
    width: 1360px;
    height: 480px;
    border-radius: 25px;
    background: #ffffff;
    margin: 16px auto;
    display: flex;
    justify-items: center;
  }



  &-box {
    // width: 673px;
    height: 480px;
    border-radius: 25px;
    padding: 48px 36px;
    position: relative;
    overflow: hidden;

    p {
      font-size: 38px;
      font-weight: bold;
      line-height: 56px;
      color: #1d1d1f;
      margin: 0;
    }

    .white {
      color: #ffffff;
    }

    &__ai {
      position: absolute;
      right: 60px;
      bottom: 40px;
      width: 208px;
      height: 182px;
      background-image: url(../assets/svg/yuanai.svg);
      background-size: contain;
    }

    &__nvwo {
      position: absolute;
      right: 45px;
      bottom: -35px;
      width: 314px;
      height: 314px;
      background-image: url(../assets/nvwo.png);
      background-size: contain;
    }

    &:nth-child(1) {
      background: #71dcf7;
    }


  }

  &-box__black {
    background: #1d1d1f !important;
  }

  &-down__left {
    width: 510px;
    align-content: center;
    padding: 0 38px;

    p {
      font-size: 38px;
      font-weight: bold;
      line-height: 56px;
      color: #1d1d1f;
    }
  }

  &-down__right {
    overflow: hidden;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding-right: 50px;

    &-wrapper {
      height: 460px;
      display: flex;
      overflow: hidden;
      margin: 10px 0;
      position: relative;

      .top-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100px;
        background-image: linear-gradient(180deg,
            #fff 24%,
            hsla(0, 0%, 100%, 0));
        z-index: 10;
      }

      .bottom-bg {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100px;
        background-image: linear-gradient(180deg,
            #fff 24%,
            hsla(0, 0%, 100%, 0));
        transform: rotate(-180deg);
        z-index: 10;
      }
    }

    .warp {
      margin-right: 12px;
    }

    .list {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .item {
      margin-bottom: 15px;
      padding: 20px;
      width: 250px;
      border-radius: 10px;
      background: #fff6d3;
    }

    .text {
      font-size: 15px;
      font-weight: normal;
      line-height: 25px;
      letter-spacing: 0em;
      margin: 0;
    }

    ::v-deep .info-wrapper {
      margin-top: 12px;
      display: flex;
      justify-items: center;
      justify-content: space-between;

      img {
        border-radius: 50%;
      }

      .el-rate__icon {
        margin-right: 0 !important;
      }

      .el-rate {
        position: relative;
        top: 2px;
      }
    }

    .info-box {
      display: flex;
      justify-items: center;

      span {
        display: inline-block;
        color: #666666;
        font-size: 13px;
        font-weight: normal;
        line-height: 25px;
        margin-left: 10px;
      }
    }
  }
}
</style>
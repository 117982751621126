<template>
    <div v-animate="{ appearClass: 'animate__fadeInRight' }" class="container animate__animated">
        <div :class="[{ itemContent: isMobile }, 'flex-center']">
            <div class="item active bg-1">
                <div class="text-sl title margin-top margin-left-xl">数智销售</div>
                <div class="ul-list margin-left-xl">
                    <ul class="text-20">
                        <li>高级群发</li>
                        <li>自动推品</li>
                        <li>自动打标签</li>
                        <li>主动关怀</li>
                        <li>千人千面触达</li>
                        <li>定制化SOP</li>
                    </ul>
                </div>
                <!-- <div class="bg-1 bg-item"></div> -->
            </div>
            <div class="item bg-2" :class="{ active: isMobile }">
                <div class="text-sl title margin-top margin-left-xl">数智客服</div>
                <div class="ul-list margin-left-xl">
                    <ul class="text-20">
                        <li>行业知识图谱</li>
                        <li>被动润色答疑</li>
                        <li>多模态会话</li>
                        <li>智能工单</li>
                        <li>引导留资</li>
                        <li>会话分析留存</li>
                    </ul>
                </div>
                <!-- <div class="bg-2 bg-item"></div> -->
            </div>
            <div class="item bg-3" disabled>
                <div class="text-sl title margin-top margin-left-xl">数智讲师</div>
                <!-- <div class="bg-3 bg-item"></div> -->
            </div>
            <div class="item bg-4" disabled>
                <div class="text-sl title margin-top margin-left-xl">数智管家</div>
                <!-- <div class="bg-4 bg-item"></div> -->
            </div>
            <div class="item bg-5" :class="{ active: isMobile }">
                <div class="text-sl title margin-top margin-left-xl">定制</div>
                <div class="ul-list margin-left-xl">
                    <div class="margin-top text-20">定制属于自己的数智员工</div>
                    <div class="more">
                        <el-button @click="openDialog">了解更多</el-button>
                    </div>
                </div>
                <!-- <div class="bg-5 bg-item"></div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        currentPosition: {
            type: Number,
            default: 0
        },
        isMobile: { type: Boolean, default: false }
    },
    data() {
        return {
            showContainer: false
        }
    },
    methods: {
        openDialog() {
            this.$emit("openDialog");
        },
        createAnimate(currentPosition) {
            this.showContainer = currentPosition > this.$refs.showContainer.offsetTop
        }
    },
    mounted() {
        let items = document.querySelectorAll('.item');
        function setActive() {
            items.forEach((item) => {
                item.classList.remove('active');
            })
            this.classList.add('active');
        }
        items.forEach((item) => {
            if (!item.hasAttribute("disabled") && !this.isMobile) {
                item.addEventListener('mouseover', setActive);
            }
        })
        this.$nextTick(() => {
            // this.showContainer = this.currentPosition > this.$refs.showContainer.offsetTop;
            console.log(this.currentPosition, this.showContainer, 'showContainer');
        })
    },
}
</script>

<style lang="scss" scoped>
.container {
    display: flex;
    // width: 90vw;
    min-width: 1400px;
    height: 400px;
    overflow: hidden;
    justify-content: center;
    
}

.item {
    color: #3971FA;
    position: relative;
    width: 210px;
    height: 327px;
    margin: 10px;
    cursor: pointer;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    transition: 0.3s cubic-bezier(0.05, 0.41, 0.61, 0.95);
    overflow: hidden;
    background: linear-gradient(180deg, #F3FBFE 0%, #D9EFFE 100%);

    .ul-list {
        display: none;

        ul {
            display: flex;
            flex-wrap: wrap;
            padding: 0;

            li {
                display: flex;
                align-items: center;
                list-style: none;
                width: 50%;
                line-height: 2;
                margin-bottom: 20px;

                &::before {
                    content: "";
                    display: block;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: #fff;
                    margin-right: 15px;
                }
            }
        }

        .more {
            margin-top: 80px;
            position: relative;
            z-index: 99;

            .el-button {
                width: 350px;
                height: 76px;
                font-size: 20px;
                color: #fff;
                border: 0 none;
                background: rgba($color: #fff, $alpha: 0.3);
            }
        }
    }
}

/* 选中态样式 */
.item.active {
    width: 450px;
    color: #fff !important;
    margin-right: 10px;
    border-radius: 10px;
    // background: #3971FA !important;

    .ul-list {
        display: block !important;
    }
}

.item .content .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    color: #fff;
    width: 100%;
}

.bg-item {
    transition: 0.3s cubic-bezier(0.05, 0.41, 0.61, 0.95);
    width: 200px;
    height: 200px;
    position: absolute;
    bottom: 20px;
    right: 5px;
}

.bg-1 {

    background: url(https://oss.isuiyou.cn/d0423f00b7929b31732c5d554e5033edf5d042e1812a948b6bbe4cbf6f35958d.png) center center no-repeat;
    background-size: cover;

    &.active {
        background: url(https://oss.isuiyou.cn/e8a1bb18bba97e9b1f818ea8a5bcad47b938e707054b8e55f905b7697a28f90c.png) center center no-repeat;
        background-size: cover;
    }
}



.bg-2 {

    background: url(https://oss.isuiyou.cn/75680d501aa7a5746e5e59fea1d85625fcdfa27f9fa646baf208be6e1577ccda.png) center center no-repeat;
    background-size: cover;

    &.active {

        background: url(https://oss.isuiyou.cn/4c117a7d7befc9cdc4afb0d2c7b34afaecf96083c0f5ce805dd691c3fb19f413.png) center center no-repeat;
        background-size: cover;
    }
}

.bg-3 {
    background: url(https://oss.isuiyou.cn/de2856a0e0519f9882f440dd4433de2cf7847450d7ddf9db13ca4d5c0759cd97.png) center center no-repeat;
    background-size: cover;

    &.active {
        background: url(https://oss.isuiyou.cn/de2856a0e0519f9882f440dd4433de2cf7847450d7ddf9db13ca4d5c0759cd97.png) center center no-repeat;
        background-size: cover;
    }
}

.bg-4 {
    background: url(https://oss.isuiyou.cn/2f073f361e8326773dd6818e3a276c9668df2ca758319d6ec8dd4f2516a39a11.png) center center no-repeat;
    background-size: cover;

    &.active {
        background: url(https://oss.isuiyou.cn/2f073f361e8326773dd6818e3a276c9668df2ca758319d6ec8dd4f2516a39a11.png) center center no-repeat;
        background-size: cover;
    }
}

.bg-5 {
    background: url(https://oss.isuiyou.cn/ae4851b3096eb7fcfac054445c20122241957daf491fc8af31d3c8daa756cdcd.png) center center no-repeat;
    background-size: cover;

    &.active {
        background: url(https://oss.isuiyou.cn/c2ba0274db9e694ceba5e697884b9296446fbf55f18704849873493aa5805e3d.png) center center no-repeat;
        background-size: cover;
    }
}






.item.active .bg-item {
    right: -30px;
    opacity: 0.1;
    width: 300px;
    height: 300px;
}

// .item.active .content .text div {
//     opacity: 1;
// }</style>
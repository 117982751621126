<template>
  <div class="can-do" :style="domStyle">
    <el-row>
      <el-col :xs="24" :md="14">
        <div class="flex-center">
          <div class="left">
            <div class="left-wrapper">
              <h2>Real-bot</h2>
              <h1>能做什么？</h1>
              <div class="left-text-wrapper">
                <div class="text-wrapper">
                  <p>利用AI大模型，</p>
                  <p>实现多轮对话，</p>
                  <p>取代线上人工。</p>
                </div>
                <div class="text-wrapper">
                  <p>自研行业销售专家小模型，</p>
                  <p>反哺强化业务流，</p>
                  <p>让数智员工具备更强大的自动销售能力。</p>
                </div>
              </div>
            </div>
          </div>
          <div class="left-box">
            <img class="top-img" :src="topSvg" />
            <h4>基于聊天对话的营销服务一体化平台</h4>
            <div class="left-box-text-box">
              <p>生成式AI原生应用</p>
              <p>自由切换通用大模型</p>
              <p>支持本地化部署，开箱即用</p>
            </div>
            <img :src="chatSvg" />
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :md="10">
        <div class="right">
          <div class="right-box">
            <Card v-for="(item, index) in list" :key="index" :firstSvg="item.firstSvg" :secondSvg="item.secondSvg"
              :title="item.title" :text="item.text" />
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Card from "./card.vue";
import { debounce } from "throttle-debounce";

const domStyle = {
  opacity: 1,
  transform: `perspective(1200px) translateX(0px) translateY(0px) scale(1) rotate(0deg) rotateX(0deg) rotateY(0deg) skewX(0deg) skewY(0deg) translateZ(0px)`,
  transition: "transform .3s, opacity .3s",
};
const defaultDomStyle = {
  opacity: 0.8,
  transform: `perspective(1200px) translateX(0px) translateY(0px) scale(1) rotate(0deg) rotateX(15deg) rotateY(0deg) skewX(0deg) skewY(0deg) translateZ(0px)`,
  transition: "transform .3s, opacity .3s",
};

export default {
  components: { Card },
  props: { isMobile: { type: Boolean, default: false } },
  data() {
    const list = [
      {
        title: "带上下文语义理解",
        text: "AI员工可以做到有记忆的和客户对话，几乎所有话题都可以接住，不会像传统智能机器人一样只会照话术1问1答",
        firstSvg: require("@/assets/svg/a.svg"),
        secondSvg: require("@/assets/svg/aw.svg"),
      },
      {
        title: "自动采集用户信息",
        text: "AI员工在对话中自动识别判断需要采集的信息，填充至客户中心；也可对AI员工发布指定收集任务，主动发起对话",
        firstSvg: require("@/assets/svg/b.svg"),
        secondSvg: require("@/assets/svg/bw.svg"),
      },
      {
        title: "自动个性群发",
        text: "支持按条件自动群发，群发素材可存入素材库，群发后AI无缝接管对话，群发内容支持文字、图片、文件、小程序等",
        firstSvg: require("@/assets/svg/c.svg"),
        secondSvg: require("@/assets/svg/cw.svg"),
      },
      {
        title: "自动营销",
        text: "AI员工可对客户进行新客接待、产品推荐、复购营销、阶段性逼单等，他会按客户所处阶段执行不同的营销动作",
        firstSvg: require("@/assets/svg/d.svg"),
        secondSvg: require("@/assets/svg/dw.svg"),
      },
      {
        title: "客户情绪洞察",
        text: "AI员工能够洞察客户的情绪，当他察觉到客户有不耐烦、生气、想退款等异常情绪时，会选择请求人工帮助",
        firstSvg: require("@/assets/svg/e.svg"),
        secondSvg: require("@/assets/svg/ew.svg"),
      },
      {
        title: "个性称呼",
        text: "除带昵称打招呼外，AI员工可以按照企业的业务需要，灵活改变客户称呼，不让客户感受到一丝不适",
        firstSvg: require("@/assets/svg/f.svg"),
        secondSvg: require("@/assets/svg/fw.svg"),
      },
      {
        title: "聚合聊天",
        text: "将多账号聚合，无需在多个设备间切换，快速响应客户，转人工对话列表一目了然，AI对话情况随时监控",
        firstSvg: require("@/assets/svg/h.svg"),
        secondSvg: require("@/assets/svg/hw.svg"),
      },
      {
        title: "真人无缝衔接",
        text: "当AI员工遇到无法处理的问题或判断客户即将进入逼单冲刺阶段等情况时，可选择转接人工，无感中转",
        firstSvg: require("@/assets/svg/i.svg"),
        secondSvg: require("@/assets/svg/iw.svg"),
      },
    ];

    return {
      list,
      chatSvg: require("@/assets/svg/chat.svg"),
      topSvg: require("@/assets/svg/top.svg"),
      domStyle: { ...defaultDomStyle },
    };
  },
  methods: {
    handleScroll() {
      if (this.isMobile) return
      this.handleStyle();
    },
    handleStyle() {
      const dom = this.$el;
      this.domStyle = this.getStyle(dom);
    },
    getStyle(dom) {
      const { top } = dom.getBoundingClientRect();
      const innerHeight = window.innerHeight;
      const offsetHeight = dom.offsetHeight - 200;
      if (top <= innerHeight && top >= innerHeight - offsetHeight) {
        const diff = Math.abs(innerHeight - top);
        let ratio = Math.min(diff / offsetHeight / 2, 1);
        ratio = 1 - ratio;
        const rotateXValue = 15 * ratio;
        const domStyle = {
          opacity: 0.8 + 0.2 * (1 - ratio),
          transform: `perspective(1200px) translateX(0px) translateY(0px) scale(1) rotate(0deg) rotateX(${rotateXValue}deg) rotateY(0deg) skewX(0deg) skewY(0deg) translateZ(0px)`,
          transition: "transform .3s, opacity .3s",
        };
        return domStyle;
      } else if (top > innerHeight) {
        return {
          ...defaultDomStyle,
        };
      } else {
        return {
          ...domStyle,
        };
      }
    },
  },
  mounted() {
    this.debounceFunc = debounce(6, this.handleScroll, { atBegin: false });
    window.addEventListener("scroll", this.debounceFunc);
  },
  destroyed() {
    window.removeEventListener("scroll", this.debounceFunc);
  },
};
</script>

<style lang="scss">
.can-do {
  width: 1360px;
  // height: 880px;
  border-radius: 25px;
  background: #eef0fd;
  background-image: url(../assets/gird.avif);
  background-size: cover;
  padding: 70px;
  // display: flex;
  position: relative;

  .left {
    width: 350px;
    height: 100%;

    .left-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      padding-top: 100px;
    }

    .left-text-wrapper {
      margin-left: 3px;
    }

    h2 {
      font-size: 50px;
      font-weight: 900;
      line-height: 60px;
      color: #0066fe;
      margin: 0;
      margin-bottom: 24px;

      &:hover {
        animation: shakeY 1s;
      }
    }

    h1 {
      font-size: 60px;
      font-weight: 800;
      line-height: 60px;
      margin: 0;
      margin-bottom: 42px;

      &:hover {
        animation: heartBeat 1s;
      }
    }

    .text-wrapper {
      margin-bottom: 32px;

      p {
        margin: 5px 0;
        font-size: 16px;
        color: #667085;
      }

      span {
        font-weight: normal;
        line-height: 25px;
      }
    }
  }

  .left-box {
    flex: none;
    width: 380px;
    height: 100%;
    border-radius: 10px;
    overflow: inherit;
    background-image: url(../assets/color-bg.png);
    background-size: cover;
    padding-left: 32px;
    overflow: hidden;
    transition: transform 0.3s;

    .top-img {
      width: 32px;
      height: 32px;
      background-size: contain;
      position: relative;
      left: 0;
      top: 30px;
    }

    h4 {
      font-size: 16px;
      margin: 55px 0 20px 0;
    }

    .left-box-text-box {
      position: relative;
      top: 20px;
      color: #667085;

      p {
        font-size: 14px;
        font-weight: normal;
        line-height: 25px;
        margin: 0 4px;
        color: #667085;
      }
    }

    img {
      height: 545px;
      position: relative;
      left: -60px;
      top: 30px;
    }

    &:hover {
      transform: scale(1.03);
    }
  }

  .right {
    flex: 1;
    display: flex;
    margin-right: -50px;
    width: 120%;

    .right-box {
      margin-left: 15px;
      display: flex;
      flex-wrap: wrap;
      width: 615px;
    }
  }
}
</style>
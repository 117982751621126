import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/index.vue";
import about from "../views/about.vue";
import test from "../views/test.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: index,
    meta: {
      title: "Real-bot-能挣钱的数字AI员工"
    }
  },
  {
    path: "/about",
    name: "about",
    component: about,
    meta: {
      title: "Real-bot-能挣钱的数字AI员工"
    }
  },
  {
    path: "/test",
    name: "test",
    component: test,
    meta: {
      title: "test"
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
